
        <!-- data table -->
        <div 
            *ngIf="!isMobile"
            matSort 
            class="table-div"
            (matSortChange)="sortData($event)">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th 
                        scope="col"
                        mat-sort-header="tp_username">
                        Territorial Partner
                        </th>
                        <th 
                        scope="col"
                        mat-sort-header="code">
                            Product Code
                        </th>
                        
                        <th 
                        scope="col"
                        mat-sort-header="amount">
                            Amount
                        </th>
                        
                        <th 
                        scope="col"
                        mat-sort-header="create_date">
                            Date
                        </th>
                        
                    </tr>
                </thead>
                <tbody *ngIf="!isLoading && !message">
                    <tr *ngFor="let sellIn of sellInData; index as i">
                        <td>{{ sellIn.tp_username }}</td>
                        <td>{{ sellIn.code }}</td>
                        <td>₱{{ globalservice.numberWithCommas(sellIn.amount) }}</td>
                        <td>{{ sellIn.create_date }}</td>
                    </tr>
                </tbody>

                <div 
                *ngIf="message && !isLoading" 
                class="m-auto error-message">
                    <span>{{message}}</span>
                </div>

                <div 
                *ngIf="isLoading" class="table-spinner-div">
                    <span class="sr-only"> <div class="spinner-border" role="status"> </div> Loading...</span>
                </div>
                
            </table>
        </div>

        <div 
        *ngIf="isMobile"
        class="card-list">
            
            <div *ngIf="!isLoading && !message">
                <tr *ngFor="let sellIn of sellInData; index as i">
                    <div class="items-card">
                        <span><span class="label">Territorial Partner: </span>{{ sellIn.tp_username }}</span>
                        <span><span class="label">Product Code: </span>{{ sellIn.code }}</span>
                        <span><span class="label">Amount: </span>₱{{ globalservice.numberWithCommas(sellIn.amount) }}</span>
                        <span><span class="label">Date: </span>{{ sellIn.create_date }}</span>
                    </div>
                </tr>
            </div>

            <div *ngIf="message && !isLoading" class="m-auto error-message"><span>{{message}}</span></div>

            <div *ngIf="isLoading" class="table-spinner-div">
                <span class="sr-only"> <div class="spinner-border" role="status"> </div> Loading...</span>
            </div>

        </div>