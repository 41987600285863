import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Sort, SortDirection } from '@angular/material/sort';

@Component({
  selector: 'app-mobile-table-sort',
  templateUrl: './mobile-table-sort.component.html',
  styleUrls: ['./mobile-table-sort.component.css']
})
export class MobileTableSortComponent {

  sort_order : SortDirection = '';
  sort_by = '';
  @Input() sortItems : Array<Array<string>> = []; // list of sell-in item parameter to use for sorting.
  @Output() updateSort = new EventEmitter<Sort>();
  
  sortChange(sort_by:string){
    if (sort_by != this.sort_by) {
      this.sort_by = sort_by;
      this.sort_order = 'asc';
    }else{
      switch (this.sort_order) {
        case 'asc':
          this.sort_order = 'desc';
          break;
        case 'desc':
          this.sort_order = '';
          this.sort_by = '';
          break;
        default:
          this.sort_order = 'asc';
          break;
      }
    }
    this.updateSort.emit({active:this.sort_by, direction:this.sort_order});
  }

  clearSort(){
    this.sort_order = '';
    this.sort_by = '';
  }


}
