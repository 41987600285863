import { Component } from '@angular/core';
import { GlobalService } from '../global.service';
import { CignalService } from '../cignal.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  total_revenue: string = "...";
  monthly_sales: string = "...";
  weekly_sales: string = "...";

  constructor(
    private globalService: GlobalService,
    private cignalService: CignalService,
  ){}

  ngOnInit(){

    this.getTotalRevenue();
    this.getMonthlySales();
    this.getWeeklySales();

  }

  async getTotalRevenue(){
    this.total_revenue = '₱'+this.globalService.numberWithCommas( await this.cignalService.getTotalRevenue());
  }
  async getMonthlySales(){

    this.monthly_sales = '₱'+this.globalService.numberWithCommas(await this.cignalService.getMonthlySales());
  }
  
  async getWeeklySales(){
    this.weekly_sales = '₱'+this.globalService.numberWithCommas(await this.cignalService.getWeeklySales());
  }

}
