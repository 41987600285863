<div 
*ngIf="!isLoading"
class="pagination">
        
    <button (click)="updatePage(current_page - 1)" *ngIf="(current_page - 1) >= 1" > 
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 500 500"><path d="M151.27,214.64,341.41,24.51a25,25,0,1,1,35.35,35.36L186.63,250,376.76,440.13a25,25,0,1,1-35.35,35.36L151.27,285.36,115.92,250Z"/></svg>
        <span>Prev</span>
    </button>

    <button (click)="updatePage(1)"  class="pagination-btn"  *ngIf="(current_page - 3) >= 1"> 1 </button>
    <span *ngIf="(current_page - 3) >= 1"n> ... </span>
    
    <button (click)="updatePage(current_page - 2)" class="pagination-btn" *ngIf="(current_page - 2) >= 1" >{{ current_page - 2 }}</button>
    <button (click)="updatePage(current_page - 1)" class="pagination-btn" *ngIf="(current_page - 1) >= 1" >{{ current_page - 1 }}</button>
    <button >{{ current_page }}</button>
    <button (click)="updatePage(current_page + 1)" class="pagination-btn" *ngIf="(current_page + 1) <= total_page" >{{ current_page + 1 }}</button>
    <button (click)="updatePage(current_page + 2)" class="pagination-btn" *ngIf="(current_page + 2) <= total_page" >{{ current_page + 2 }}</button>
    
    <span *ngIf="(current_page + 3) <= total_page"> ... </span>
    <button (click)="updatePage(total_page)"  class="pagination-btn" *ngIf="(current_page + 3) <= total_page">{{ total_page }}</button>
    
    <button (click)="updatePage(current_page + 1)" *ngIf="(current_page + 1) <= total_page"> 
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 500 500"><path d="M348.73,214.64,158.59,24.51a25,25,0,1,0-35.35,35.36L313.37,250,123.24,440.13a25,25,0,1,0,35.35,35.36L348.73,285.36,384.08,250Z"/></svg>
        <span>Next</span>
    </button>

</div>

<div 
*ngIf="isLoading"
class="pagination">
    Loading pages...
</div>