<div class="wrapper">
    
    <app-sidebar [page]="4"></app-sidebar>
    
    <div class="w-100">
        <app-top-bar></app-top-bar>

        <div class="card">
            
            <!-- title and export -->
            <div class="header">
                
                <div class="title">
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="#ef182e" width="30" height="30" viewBox="0 0 500 500"><path d="M292.1,54.24a54.6,54.6,0,0,0-23.74-10.43l-14.07-2.6L88.05,10.51C44.23,2.41,2.41,44.23,10.51,88.05l30.7,166.24,2.6,14.07A54.6,54.6,0,0,0,54.24,292.1a51.69,51.69,0,0,0,9.2,9.2L240.07,477.93c18,18,48.86,16.48,68.83-3.49L474.44,308.9c20-20,21.53-50.79,3.49-68.83L301.3,63.44A51.69,51.69,0,0,0,292.1,54.24ZM123.4,123.4a39.36,39.36,0,1,1,0-55.66A39.36,39.36,0,0,1,123.4,123.4Z"/></svg>
                    <h2>Sell-Out History</h2>              
                </div>

                <button class="filter-toggle-btn export-btn" (click)="showFilter(true)">
                    <svg id="Layer_1" data-name="Layer 1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><path d="M34.4,104H62.86v7.73a40,40,0,0,0,80,0V104H465.6a25,25,0,1,0,0-50H142.86V46.26a40,40,0,0,0-80,0V54H34.4a25,25,0,0,0,0,50Z"/><path d="M465.6,396H142.86v-7.74a40,40,0,0,0-80,0V396H34.4a25,25,0,1,0,0,50H62.86v7.73a40,40,0,0,0,80,0V446H465.6a25,25,0,0,0,0-50Z"/><path d="M465.6,225H437.14v-7.73a40,40,0,1,0-80,0V225H34.4a25,25,0,0,0,0,50H357.14v7.73a40,40,0,0,0,80,0V275H465.6a25,25,0,0,0,0-50Z"/></svg>
                </button>

                <button (click)="exportSellOutRecord()">
                    <svg id="Layer_1" data-name="Layer 1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><defs><style>.cls-1{fill:none;}</style></defs><path class="cls-1" d="M106.8,134.48H357.4a24.52,24.52,0,1,0,0-49H106.8a24.52,24.52,0,0,0,0,49Z"/><path d="M106.8,382.36a24.52,24.52,0,0,1,0-49h70.63A169.35,169.35,0,0,1,201,258.42H106.8a24.52,24.52,0,0,1,0-49H245a170.42,170.42,0,0,1,211.52,5.29V67.15A57.66,57.66,0,0,0,398.85,9.5H65.35A57.66,57.66,0,0,0,7.69,67.15v333.5a57.66,57.66,0,0,0,57.66,57.66H219.58A169.93,169.93,0,0,1,181,382.36Zm0-296.91H357.4a24.52,24.52,0,1,1,0,49H106.8a24.52,24.52,0,0,1,0-49Z"/><path d="M456.51,250.26A144.9,144.9,0,0,0,203,333.32q-.51,6.07-.52,12.29a144.9,144.9,0,1,0,254-95.35ZM422.1,383a24.52,24.52,0,1,1-49,0V355.53l-26.82,26.83L314.58,414a24.52,24.52,0,0,1-37.23-31.67,24.84,24.84,0,0,1,2.55-3l46-46,14.27-14.27H312.79a24.52,24.52,0,1,1,0-49H422.1Z"/></svg>
                    <span>Export CSV</span>
                </button>

            </div>
            
            
            <div class="filter-div" id="filter_div">
                
                <!-- filters 1-->
                <div class="filter-list">

                    <!-- filter title  -->
                    <div class="filter-title">
                        <div class="title">
                            <svg id="Layer_1" data-name="Layer 1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><path d="M34.4,104H62.86v7.73a40,40,0,0,0,80,0V104H465.6a25,25,0,1,0,0-50H142.86V46.26a40,40,0,0,0-80,0V54H34.4a25,25,0,0,0,0,50Z"/><path d="M465.6,396H142.86v-7.74a40,40,0,0,0-80,0V396H34.4a25,25,0,1,0,0,50H62.86v7.73a40,40,0,0,0,80,0V446H465.6a25,25,0,0,0,0-50Z"/><path d="M465.6,225H437.14v-7.73a40,40,0,1,0-80,0V225H34.4a25,25,0,0,0,0,50H357.14v7.73a40,40,0,0,0,80,0V275H465.6a25,25,0,0,0,0-50Z"/></svg>
                            <h3>Filters</h3>
                        </div>
                        <div class="close-filter" (click)="showFilter(false)">
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><path d="M285.36,250,470.91,64.44a25,25,0,0,0-35.35-35.35L250,214.64,64.44,29.09A25,25,0,0,0,29.09,64.44L214.64,250,29.09,435.56a25,25,0,0,0,35.35,35.35L250,285.36,435.56,470.91a25,25,0,0,0,35.35-35.35Z"/></svg>
                        </div>
                    </div>
                    
                    <!-- Territorial Partner Username filter -->
                    <div ngbDropdown class="d-inline-block">
                        <input 
                        type="text" 
                        class="form-control input-dropdown" 
                        id="dropdownTpUsernamesFilter" 
                        placeholder="Territorial Partner"
                        [(ngModel)] = "tpNameFilter"
                        (ngModelChange)="tpNameFilterChange()"
                        (focusout)="setOrderRecordFilter()"
                        ngbDropdownToggle>
                        <div ngbDropdownMenu aria-labelledby="dropdownTpUsernamesFilter" class="dropdown-filter-list">
                            <button ngbDropdownItem *ngFor="let tp_username of tp_usernames; index as i" (click)="setTpNameFilter(tp_username.tp_username)">
                                {{tp_username.tp_username}}
                            </button>
                        </div>
                    </div>

                    <input type="text" class="form-control search-input" id="searchInput" placeholder="Search" [(ngModel)]="temp_searchValue">
                    
                    <div class="search-n-clear">
                        <button class="search-btn" (click)="searchSellOut()">
                            <div>
                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 500 500"><path d="M479,451,374.78,346.74a204.87,204.87,0,0,0,50.33-134.67c0-113.44-92.29-205.73-205.73-205.73S13.65,98.63,13.65,212.07s92.29,205.72,205.73,205.72a204.61,204.61,0,0,0,118.26-37.48l106,106A25,25,0,0,0,479,451ZM63.65,212.07c0-85.87,69.86-155.73,155.73-155.73S375.11,126.2,375.11,212.07a155.27,155.27,0,0,1-47.35,111.7,25.15,25.15,0,0,0-9.9,8.83,155,155,0,0,1-98.48,35.19C133.51,367.79,63.65,297.93,63.65,212.07Z"/></svg>
                                <span>Search</span>
                            </div>
                        </button>
                        
                        <button (click)="clearsearchSellOut()" type="button" class="btn btn-outline-primary">
                            <span>Clear</span>
                        </button>
                    </div>

                </div>

            </div>

        </div>

        <!-- data table -->
        <div matSort (matSortChange)="sortData($event)" class="table-div">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th 
                        scope="col"
                        mat-sort-header="tp_username">
                            Territorial Partner
                        </th>
                        
                        <th 
                        scope="col"
                        mat-sort-header="total_epins">
                            Number of E-pins
                        </th>
                        
                        <th 
                        scope="col"
                        mat-sort-header="amount">
                            Equivalent Total Sales Amount
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="!isLoading && !message">
                    <tr *ngFor="let sellOutRecord of sellOutRecords; index as i">
                        <td>{{ sellOutRecord.tp_username }}</td>
                        <td>{{ sellOutRecord.total_epins }}</td>
                        <td>₱{{ globalService.numberWithCommas(sellOutRecord.amount) }}</td>
                    </tr>
                </tbody>
            </table>

            <div *ngIf="message && !isLoading" class="m-auto error-message"><span>{{message}}</span></div>

            
            <div *ngIf="isLoading" class="table-spinner-div">
                <span class="sr-only"> <div class="spinner-border" role="status"> </div> Loading...</span>
            </div>
        </div>
    
    <div 
    class="pagination"
    *ngIf="!isTotalLoading">
                
        <button (click)="updateSellOutHistoryRecordPage(current_page - 1)" *ngIf="(current_page - 1) >= 1" > Prev </button>

        <button (click)="updateSellOutHistoryRecordPage(1)"  class="pagination-btn"  *ngIf="(current_page - 3) >= 1"> 1 </button>
        <span *ngIf="(current_page - 3) >= 1"n> ... </span>
        
        <button (click)="updateSellOutHistoryRecordPage(current_page - 2)" class="pagination-btn" *ngIf="(current_page - 2) >= 1" >{{ current_page - 2 }}</button>
        <button (click)="updateSellOutHistoryRecordPage(current_page - 1)" class="pagination-btn" *ngIf="(current_page - 1) >= 1" >{{ current_page - 1 }}</button>
        <button >{{ current_page }}</button>
        <button (click)="updateSellOutHistoryRecordPage(current_page + 1)" class="pagination-btn" *ngIf="(current_page + 1) <= total_page" >{{ current_page + 1 }}</button>
        <button (click)="updateSellOutHistoryRecordPage(current_page + 2)" class="pagination-btn" *ngIf="(current_page + 2) <= total_page" >{{ current_page + 2 }}</button>
        
        <span *ngIf="(current_page + 3) <= total_page"> ... </span>
        <button (click)="updateSellOutHistoryRecordPage(total_page)"  class="pagination-btn" *ngIf="(current_page + 3) <= total_page">{{ total_page }}</button>
        
        <button (click)="updateSellOutHistoryRecordPage(current_page + 1)" *ngIf="(current_page + 1) <= total_page"> Next </button>

    </div>

    <div 
    *ngIf="isTotalLoading && !isLoading"
    class="pagination">
        Loading pages...
    </div>

</div>