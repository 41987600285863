import { Injectable } from '@angular/core';
import { AuthService } from '../../auth.service';


import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import { firstValueFrom, lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { GlobalService } from '../../global.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SelloutService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    public globalService: GlobalService
  ) { }

  
// SELL-OUT TP
  // DEFAULT
  async getSellOutTpRecord(limit:number = 20, offset:number = 0, sort_by:string, sort_order:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
   
    var params = `limit=${limit}&offset=${offset}`;
    if (sort_by && sort_order) {
      params += `&sort_by=${sort_by}&sort_order=${sort_order}`
    }

    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sellouts/tp?${params}`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        
        return {
          "items": response.body.items,
          "message": "",
        }; 

      } catch (e:any) {
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }
  async getSellOutTpTotalRecord(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();

    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sellouts/tp/total`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        
        return {
          "total": response.body.total,
          "message": "",
        }; 

      } catch (e:any) {
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }

  // SEARCH
  async searchSellOutTp(limit:number = 20, offset:number = 0, searchValue:string, sort_by:string, sort_order:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/cignalsellouts/tp/by/term`;

      var body:any = {};
      body.search_term = searchValue;
      body.limit = limit;
      body.offset = offset;

      if (sort_by && sort_order) {
        body.sort_by = sort_by,
        body.sort_order = sort_order
      }
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );  
        return {
          "items": response.body.items,
          "message": "",
        }; 
      }catch (e:any) {
        console.log(e.error.message);
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }

    

    }else{
      return false;
    }
  }
  async searchSellOutTpTotal(searchValue:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/cignalsellouts/tp/by/term/total`;

      var body:any = {};
      body.search_term = searchValue;

      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );  
        return {
          "total": response.body.total,
          "message": "",
        }; 
      }catch (e:any) {
        console.log(e.error.message);
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }

    

    }else{
      return false;
    }
  }

  // FILTER
  async filterSellOutTp(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/cignalsellouts/tp/by/criteria`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
            this.http.post(APIEndPoint, body, {
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                },
                observe: 'response',
              }
            )
        );    
  
        return {
          "items": response.body.items,
          "message": "",
        }; 
        
      }catch (e:any) {
        console.log(e.error.message);
        if(e.error.message.includes('A date range is required.')){
          e.error.message = "Date range is required.";
        }
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }
  async filterSellOutTpTotal(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/cignalsellouts/tp/by/criteria/total`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
            this.http.post(APIEndPoint, body, {
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                },
                observe: 'response',
              }
            )
        );    
  
        return {
          "total": response.body.total,
          "message": "",
        }; 
        
      }catch (e:any) {
        console.log(e.error.message);
        if(e.error.message.includes('A date range is required.')){
          e.error.message = "Date range is required.";
        }
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }



// SELL-OUT DEALER 
  // DEFAULT
  async getSellOutDealerRecord(limit:number = 20, offset:number = 0, sort_by:string, sort_order:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var params = `limit=${limit}&offset=${offset}`;
      if (sort_by && sort_order) {
        params += `&sort_by=${sort_by}&sort_order=${sort_order}`
      }
      var APIEndPoint:string = `${environment.cignalApiUrl}/sellouts/dealer?${params}`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        
        return {
          "items": response.body.items,
          "message": "",
        }; 

      } catch (e:any) {
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }
  async getSellOutDealerTotalRecord(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sellouts/dealer/total`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        
        return {
          "total": response.body.total,
          "message": "",
        }; 

      } catch (e:any) {
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }

  // SEARCH
  async searchSellOutDealer(limit:number = 20, offset:number = 0, searchValue:string, sort_by:string, sort_order:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/cignalsellouts/dealer/by/term`;

      var body:any = {};
      body.search_term = searchValue;
      body.limit = limit;
      body.offset = offset;

      if (sort_by && sort_order) {
        body.sort_by = sort_by,
        body.sort_order = sort_order
      }
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );  
        return {
          "items": response.body.items,
          "message": "",
        }; 
      }catch (e:any) {
        console.log(e.error.message);
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }

    

    }else{
      return false;
    }
  }
  async searchSellOutDealerTotal( searchValue:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/cignalsellouts/dealer/by/term/total`;

      var body:any = {};
      body.search_term = searchValue;

      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );  
        return {
          "total": response.body.total,
          "message": "",
        }; 
      }catch (e:any) {
        console.log(e.error.message);
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }

    

    }else{
      return false;
    }
  }

  // FILTER
  async filterSellOutDealer(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/cignalsellouts/dealer/by/criteria`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
            this.http.post(APIEndPoint, body, {
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                },
                observe: 'response',
              }
            )
        );    
  
        return {
          "items": response.body.items,
          "message": "",
        }; 
        
      }catch (e:any) {
        console.log(e.error.message);
        if(e.error.message.includes('A date range is required.')){
          e.error.message = "Date range is required.";
        }
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }
  async filterSellOutDealerTotal(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/cignalsellouts/dealer/by/criteria/total`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
            this.http.post(APIEndPoint, body, {
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                },
                observe: 'response',
              }
            )
        );    
  
        return {
          "total": response.body.total,
          "message": "",
        }; 
        
      }catch (e:any) {
        console.log(e.error.message);
        if(e.error.message.includes('A date range is required.')){
          e.error.message = "Date range is required.";
        }
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }




// SELL-OUT RETAILER
  // DEFAULT
  async getSellOutRetailerRecord(limit:number = 20, offset:number = 0, sort_by:string, sort_order:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var params = `limit=${limit}&offset=${offset}`;
      if (sort_by && sort_order) {
        params += `&sort_by=${sort_by}&sort_order=${sort_order}`
      }
      var APIEndPoint:string = `${environment.cignalApiUrl}/sellouts/retailer?${params}`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        
        return {
          "items": response.body.items,
          "message": "",
        }; 

      } catch (e:any) {
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }
  async getSellOutRetailerTotalRecord(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();

    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sellouts/retailer/total`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        
        return {
          "total": response.body.total,
          "message": "",
        }; 

      } catch (e:any) {
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }

  // SEARCH
  async searchSellOutRetailer(limit:number = 20, offset:number = 0, searchValue:string, sort_by:string, sort_order:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/cignalsellouts/retailer/by/term`;

      var body:any = {};
      body.search_term = searchValue;
      body.limit = limit;
      body.offset = offset;

      if (sort_by && sort_order) {
        body.sort_by = sort_by,
        body.sort_order = sort_order
      }
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );  
        return {
          "items": response.body.items,
          "message": "",
        }; 
      }catch (e:any) {
        console.log(e.error.message);
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }

    

    }else{
      return false;
    }
  }
  async searchSellOutRetailerTotal(searchValue:string){

    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/cignalsellouts/retailer/by/term/total`;

      var body:any = {};
      body.search_term = searchValue;

      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );  
        return {
          "total": response.body.total,
          "message": "",
        }; 
      }catch (e:any) {
        console.log(e.error.message);
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }

    

    }else{
      return false;
    }
  }

  // FILTER
  async filterSellOutRetailer(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/cignalsellouts/retailer/by/criteria`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
            this.http.post(APIEndPoint, body, {
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                },
                observe: 'response',
              }
            )
        );    
  
        return {
          "items": response.body.items,
          "message": "",
        }; 
        
      }catch (e:any) {
        console.log(e.error.message);
        if(e.error.message.includes('A date range is required.')){
          e.error.message = "Date range is required.";
        }
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }
  async filterSellOutRetailerTotal(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/cignalsellouts/retailer/by/criteria/total`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
            this.http.post(APIEndPoint, body, {
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                },
                observe: 'response',
              }
            )
        );    
  
        return {
          "total": response.body.total,
          "message": "",
        }; 
        
      }catch (e:any) {
        console.log(e.error.message);
        if(e.error.message.includes('A date range is required.')){
          e.error.message = "Date range is required.";
        }
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }

}
