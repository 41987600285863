import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-tp-sell-out',
  templateUrl: './tp-sell-out.component.html',
  styleUrls: ['./tp-sell-out.component.css']
})
export class TpSellOutComponent {

  @Input() sellOutData : Array<any> = [];  
  @Input() isLoading : boolean = false;
  @Input() message : String = "";
  isMobile : boolean = false;
  @Output() updateSort = new EventEmitter<Sort>();

  constructor(
    public globalservice : GlobalService
  ){}

  ngOnInit(){
    this.isMobile = this.globalservice.isMobile();
  }

  sortData(sort:Sort){
    this.updateSort.emit(sort);
  }

}
