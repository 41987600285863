import { Component } from '@angular/core';
import { GlobalService } from '../global.service';
import { CignalService } from '../cignal.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import {Sort} from '@angular/material/sort';
import { PurchaseOrderHistoryService } from '../services/purchase-order-history/purchase-order-history.service';

@Component({
  selector: 'app-purchase-order-history',
  templateUrl: './purchase-order-history.component.html',
  styleUrls: ['./purchase-order-history.component.css']
})
export class PurchaseOrderHistoryComponent {

  orderHistoryRecords:Array<any> = [];
  total_page:number = 0;
  current_page:number = 1;
  isLoading:boolean = false;
  requestId: number = 0;
  isTotalLoading:boolean = false;
  requestTotalId: number = 0;
  isMobile = false;

  // search variables
  temp_searchValue:string = "";
  searchValue:string = "";
      
  // filter variables
  // TP NAME FILTER
  tp_usernames:Array<any> = [];
  tp_usernames_bkup:Array<any> = [];
  tpNameFilter: string = "";

  // SELLER NAME FILTER
  seller_usernames:Array<any> = [];
  seller_usernames_bkup:Array<any> = [];
  sellerUsernameFilter: string = "";

  //  DATE FILTER
  minDateFilter: NgbDateStruct = {year:  2000 , month: 1, day: 1}; 
  maxDateFilter: NgbDateStruct = {year:  2100 , month: 12, day: 31}; 
  fromDateFilter: any = ""; 
  toDateFilter: any = "";
  
  
  sellerRoleNameFilter: string = "";
  statusFilter: string = "";
  filterJson:any = {};
  message:string = "";
  
  constructor(
    private cignalService: CignalService,
    public globalService: GlobalService,
    private router: Router,
    private surchaseOrderHistoryService: PurchaseOrderHistoryService
  ){}
  
  ngOnInit(){
    this.isMobile = this.globalService.isMobile();
    this.getAllTpNames();
    this.getSellerUsernames();
    this.initializeData();
  }

  async initializeData(){
    this.isTotalLoading = true;
    await this.getOrderHistoryRecord();
    await this.getOrderHistoryTotalRecord();
  }
  
  // TP NAME FILTER
  async getAllTpNames(){
    var result = await this.cignalService.getAllTpNames();
    if (result) {
      if (result instanceof Array) {
        this.tp_usernames = result;
        this.tp_usernames_bkup = result;
      }
    }
  }
  setTpNameFilter(tpName:string){
    this.tpNameFilter = tpName;
    this.setOrderRecordFilter();
  }
  tpNameFilterChange(){
      this.tp_usernames = this.tp_usernames_bkup;
      if(this.tpNameFilter){
        this.tp_usernames = this.tp_usernames.filter(
          tp_username => tp_username.tp_username.indexOf(this.tpNameFilter) >= 0);
      }
  }

  // SELLER USERNAME FILTER
  async getSellerUsernames(){
    var result = await this.cignalService.getAllUserNames();
    if (result) {
      if (result instanceof Array) {
        this.seller_usernames = result;
        this.seller_usernames_bkup = result;
      }
    }
  }
  setSellerUsernameFilter(sellerUsername:string){
    this.sellerUsernameFilter = sellerUsername;
    this.setOrderRecordFilter();
  }
  sellerUsernameFilterChange(){
      this.seller_usernames = this.seller_usernames_bkup;
      if(this.sellerUsernameFilter){
        this.seller_usernames = this.seller_usernames.filter(
          seller_username => seller_username.username.indexOf(this.sellerUsernameFilter) >= 0);
      }
  }

  async exportPurchaseOrderRecord(){
    var offset = (this.current_page * 10) - 10; 
    this.message = "";

    // get sales record by search
    if (this.searchValue) {
     var result = await this.cignalService.exportPurchaseOrderByTerms(this.searchValue);
    }
    // get sales record by filter
    else if(Object.keys(this.filterJson).length > 0)
    {
     var result = await this.cignalService.exportPurchaseOrderByCriteria(this.filterJson);
    }
    // get all sales record
    else{
      var result = await this.cignalService.exportPurchaseOrderRecord();
    }

    if (result) {
      if (result.success) {

        this.globalService.showSnackbar(result.message,"Ok");
        this.router.navigateByUrl("/exports");
      
      }else{
        console.log(result.message);
        this.message = result.message;
      }
    }
  }


  // FILTER FUNCTIONS  
  setOrderRecordFilter(){
  var update_flg = false;

    // FROM DATE FILTER
    if (this.fromDateFilter) { 
      var new_from_date = `${this.fromDateFilter.year}-${this.fromDateFilter.month}-${this.fromDateFilter.day}`;
      if (this.filterJson.from_date != new_from_date) {
        this.filterJson.from_date = new_from_date;
        update_flg = true;
      }
    }else{
      if (this.filterJson.from_date) {
        delete this.filterJson.from_date;
        update_flg = true;  
      }
    }
    
    // TO DATE FILTER
    if (this.toDateFilter) { 
      var new_to_date = this.globalService.addOneDayToEndDate(this.toDateFilter);
      if (this.filterJson.to_date != new_to_date) {
        this.filterJson.to_date = new_to_date;    
        update_flg = true;
      }  
    }else{
      if (this.filterJson.to_date) {
        delete this.filterJson.to_date;
        update_flg = true;
      }
    }
    
    // TP NAME FILTER
    if (this.tpNameFilter) { 
      if (this.filterJson.tp_username != this.tpNameFilter) {
        this.filterJson.tp_username = this.tpNameFilter;
        update_flg = true;
      } 
    }else{
      if (this.filterJson.tp_username) {
        delete this.filterJson.tp_username;
        update_flg = true;
      }
    }

    // STATUS FILTER
    if (this.statusFilter) { 
      if (this.filterJson.paid != this.statusFilter) {
        this.filterJson.paid = this.statusFilter;
        update_flg = true;
      } 
    }else{
      if (this.filterJson.paid) {
        delete this.filterJson.paid;
        update_flg = true;
      }
    }

    // SELLER ROLE NAME FITER
    if (this.sellerRoleNameFilter) {  
      if(this.filterJson.seller_rolename != this.sellerRoleNameFilter){
        this.filterJson.seller_rolename = this.sellerRoleNameFilter;
        update_flg = true;
      } 
    }else{
      if (this.filterJson.seller_rolename) {
        delete this.filterJson.seller_rolename;
        update_flg = true;
      }
    }
    
    // SELLER USERNAME FILTER
    if (this.sellerUsernameFilter) { 
      if (this.filterJson.seller_username != this.sellerUsernameFilter) {
        this.filterJson.seller_username = this.sellerUsernameFilter;
        update_flg = true;
      } 
    }else{
      if (this.filterJson.seller_username) {
        delete this.filterJson.seller_username;
        update_flg = true;
      }
    }

    if (update_flg) {
      this.current_page = 1;
      this.searchValue = "";
      this.temp_searchValue = "";
      this.initializeData();
    }

  }
  setStatusFilter(statusFilter:string){
    this.statusFilter = statusFilter;
    this.setOrderRecordFilter();
  }
  updateAvailableDateRange(){
    // for fromDateFilter
    if (typeof(this.fromDateFilter) == "string") {
      // convert string to NgbDateStruct if date was typed
      // return empty string if entered text is an invalid date format
      this.fromDateFilter = this.globalService.formatStringToNgbDateStruct(this.fromDateFilter);
    }
    
    if (typeof(this.fromDateFilter) == "object") {
      this.minDateFilter = this.fromDateFilter;
      this.setOrderRecordFilter();
    }else if (typeof(this.fromDateFilter ) == "string") {
      this.minDateFilter = {year:  1900 , month: 1, day: 1};
    }
    // for toDateFilter
    if (typeof(this.toDateFilter) == "string") {
      // convert string to NgbDateStruct if date was typed
      // return empty string if entered text is an invalid date format
      this.toDateFilter = this.globalService.formatStringToNgbDateStruct(this.toDateFilter);
    }

    if (typeof(this.toDateFilter) == "object") {
      this.maxDateFilter = this.toDateFilter;
      this.setOrderRecordFilter();
    }else if (typeof(this.toDateFilter ) == "string") {
      this.maxDateFilter = {year:  2100 , month: 12, day: 31};
    }
  }
  showFilter(val:boolean){
    let sidebar = document.getElementById("filter_div") as HTMLElement;
    if (sidebar) {
      var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      if (width < 600) {
        if (val) {
          sidebar.style.display = "flex";
        }else{
          sidebar.style.display = "none";
        } 
      }
    }
  }

  
  // SORT FUNCTION
  sortData(sort:Sort){
    delete  this.filterJson.sort_by;
    delete  this.filterJson.sort_order;
    if (sort.direction) {
      this.filterJson.sort_by = sort.active;
      this.filterJson.sort_order = sort.direction;
    }
    this.getOrderHistoryRecord();
  }


  // PAGINATION
  updateOrderHistoryRecordPage(pageNo:number){
    this.current_page = pageNo;
    this.getOrderHistoryRecord();
  }


  // SEARCH FUNCTIONS
  searchOrder(){
    if (this.searchValue.trim() != this.temp_searchValue.trim()) {
        this.searchValue = this.temp_searchValue.trim();
        this.current_page = 1;
      //  reset filter value
        this.minDateFilter = {year:  2000 , month: 1, day: 1}; 
        this.maxDateFilter = {year:  2100 , month: 12, day: 31}; 
        this.fromDateFilter = ""; 
        this.toDateFilter = ""; 
        this.tpNameFilter = "";
        this.sellerRoleNameFilter = "";
        this.sellerUsernameFilter = "";
        this.statusFilter = "";
        this.filterJson = {};
    }
    this.showFilter(false);
    this.initializeData();
  }
  clearSearchOrder(){
    this.searchValue = "";
    this.temp_searchValue = "";
    this.current_page = 1;
    //  reset filter value
    this.minDateFilter = {year:  2000 , month: 1, day: 1}; 
    this.maxDateFilter = {year:  2100 , month: 12, day: 31}; 
    this.fromDateFilter = ""; 
    this.toDateFilter = ""; 
    this.tpNameFilter = "";
    this.sellerRoleNameFilter = "";
    this.sellerUsernameFilter = "";
    this.statusFilter = "";
    this.filterJson = {};
    this.showFilter(false);
    this.initializeData();
  }

  async getOrderHistoryRecord(){
    var sort_by = this.filterJson.sort_by ?? "";
    var sort_order = this.filterJson.sort_order ?? "";
     
    delete this.filterJson.limit;
    delete this.filterJson.offset;
    
    this.message = "";
    this.isLoading = true;
    var itemsPerPage = 15;
    var offset = (this.current_page * itemsPerPage) - itemsPerPage;
    this.requestId += 1;
    var requestId = this.requestId;

    // get sales record by search
    if (this.searchValue) {
     var result = await this.surchaseOrderHistoryService.searchOrders(itemsPerPage, offset, this.searchValue, sort_by, sort_order);
    }
    // get sales record by filter
    else if(Object.keys(this.filterJson).length > 0)
    {
     this.filterJson.limit = itemsPerPage;
     this.filterJson.offset = (this.current_page * itemsPerPage) - itemsPerPage;
     var result = await this.surchaseOrderHistoryService.filterPurchaseOrders(this.filterJson);
    }
    // get all sales record
    else{
      var result = await this.surchaseOrderHistoryService.getOrderHistoryRecord(itemsPerPage, offset);
    }

    this.mountData(result, requestId)
    
  }
  mountData(result: any, requestId: number){
    if (requestId == this.requestId) {
      if (result) {
        if (result.message) {
          this.message = result.message;
        }else{
          this.orderHistoryRecords = result.items;
        }
      }

      if (this.orderHistoryRecords.length == 0 && this.total_page == 0 && !this.message) {
        this.message = 'No item available.'
      }
      this.isLoading = false;
      
    }
  }

  async getOrderHistoryTotalRecord(){
    var itemsPerPage = 15;
    this.requestTotalId += 1;
    var requestTotalId = this.requestTotalId;

    // get sales record by search
    if (this.searchValue) {
     var result = await this.surchaseOrderHistoryService.searchOrdersTotal( this.searchValue );
    }
    // get sales record by filter
    else if(Object.keys(this.filterJson).length > 0)
    {
     this.filterJson.limit = itemsPerPage;
     this.filterJson.offset = (this.current_page * itemsPerPage) - itemsPerPage;
     var result = await this.surchaseOrderHistoryService.filterPurchaseOrdersTotal(this.filterJson);
    }
    // get all sales record
    else{
      var result = await this.surchaseOrderHistoryService.getOrderHistoryTotalRecord();
    }

    this.mountDataTotal(result, 15, requestTotalId)
    
  }
  mountDataTotal(result: any, itemsPerPage: number, requestId: number){
    if (requestId == this.requestId) {
      if (result) {
        if (!result.message) {
          if((result.total % 10) == 0){
            this.total_page = Math.floor(result.total / itemsPerPage );  
          }else{
            this.total_page = Math.floor(result.total / itemsPerPage ) + 1;
          }
        }
      }

      this.isTotalLoading = false;
      
    }
  }
}
