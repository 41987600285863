import { Component, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../global.service';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.css']
})
export class DateRangePickerComponent {
  
  @Output() updateDateRangeFilter = new EventEmitter<any>();

  //  DATE FILTER
  minDateFilter: NgbDateStruct = {year:  2000 , month: 1, day: 1}; 
  maxDateFilter: NgbDateStruct = {year:  2100 , month: 12, day: 31}; 
  fromDateFilter: any = ""; 
  toDateFilter: any = "";
  current_page = 1 ;
  total_page = 10;

  
  constructor(
    public globalService: GlobalService,
  ){}

  setOrderRecordFilter(){
    var dateRange = {
      'start_date':this.fromDateFilter, 
      'end_date':this.toDateFilter
    };
    if (!this.fromDateFilter) { delete dateRange.start_date};
    if (!this.toDateFilter) { delete dateRange.end_date};
    this.updateDateRangeFilter.emit(dateRange);
  }

  updateAvailableDateRange(){
    // for fromDateFilter
    if (typeof(this.fromDateFilter) == "string") {
      // convert string to NgbDateStruct if date was typed
      // return empty string if entered text is an invalid date format
      this.fromDateFilter = this.globalService.formatStringToNgbDateStruct(this.fromDateFilter);
    }
    
    if (typeof(this.fromDateFilter) == "object") {
      this.minDateFilter = this.fromDateFilter;
      this.setOrderRecordFilter();
    }else if (typeof(this.fromDateFilter ) == "string") {
      this.minDateFilter = {year:  1900 , month: 1, day: 1};
    }
    // for toDateFilter
    if (typeof(this.toDateFilter) == "string") {
      // convert string to NgbDateStruct if date was typed
      // return empty string if entered text is an invalid date format
      this.toDateFilter = this.globalService.formatStringToNgbDateStruct(this.toDateFilter);
    }

    if (typeof(this.toDateFilter) == "object") {
      this.maxDateFilter = this.toDateFilter;
      this.setOrderRecordFilter();
    }else if (typeof(this.toDateFilter ) == "string") {
      this.maxDateFilter = {year:  2100 , month: 12, day: 31};
    }
  }

  clearDateRange(){
    this.minDateFilter = {year:  2000 , month: 1, day: 1}; 
    this.maxDateFilter = {year:  2100 , month: 12, day: 31}; 
    this.fromDateFilter = ""; 
    this.toDateFilter = "";
  }
}