<div class="wrapper">
    
    <app-sidebar [page]="5"></app-sidebar>
    
    <div class="w-100">
        <app-top-bar></app-top-bar>

        <!-- data table -->
        <div class="card table-div">
            <table *ngIf="!isLoading" class="table table-striped">
                <thead>
                    <tr>
                        <!-- <th scope="col">File Name</th> -->
                        <th scope="col"><span>File Name</span></th>
                        <th scope="col"><span>Date</span></th>
                        <th scope="col"><span>Time</span></th>
                        <th scope="col"><span>Status</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let exportRequestRecord of exportRequestRecords; index as i">
                        <td><span>{{ exportRequestRecord.fileName }}</span></td>
                        <td><span>{{ exportRequestRecord.createdAt.split("T")[0] }}</span></td>
                        <td><span>{{ exportRequestRecord.createdAt.split("T")[1].split(".")[0] }}</span></td>
                        <td><span [class]="exportRequestRecord.status">{{ exportRequestRecord.status }}</span></td>
                        <td><button
                        [disabled]=" exportRequestRecord.status != 'COMPLETED' " 
                        (click)="downloadExportedCSV(exportRequestRecord.location, exportRequestRecord.fileName)" 
                        class="download-btn">
                            Download 
                        </button></td>
                    </tr>
                </tbody>
            </table>
            
            <div *ngIf="isLoading" class="table-spinner-div">
                <span class="sr-only"> <div class="spinner-border" role="status"> </div> Loading...</span>
            </div>
            
            
            <div class="pagination">
                
                <button (click)="updateOrderHistoryRecordPage(current_page - 1)" *ngIf="(current_page - 1) >= 1" > Prev </button>

                <button (click)="updateOrderHistoryRecordPage(1)"  class="pagination-btn"  *ngIf="(current_page - 3) >= 1"> 1 </button>
                <span *ngIf="(current_page - 3) >= 1"n> ... </span>
                
                <button (click)="updateOrderHistoryRecordPage(current_page - 2)" class="pagination-btn" *ngIf="(current_page - 2) >= 1" >{{ current_page - 2 }}</button>
                <button (click)="updateOrderHistoryRecordPage(current_page - 1)" class="pagination-btn" *ngIf="(current_page - 1) >= 1" >{{ current_page - 1 }}</button>
                <button >{{ current_page }}</button>
                <button (click)="updateOrderHistoryRecordPage(current_page + 1)" class="pagination-btn" *ngIf="(current_page + 1) <= total_page" >{{ current_page + 1 }}</button>
                <button (click)="updateOrderHistoryRecordPage(current_page + 2)" class="pagination-btn" *ngIf="(current_page + 2) <= total_page" >{{ current_page + 2 }}</button>
                
                <span *ngIf="(current_page + 3) <= total_page"> ... </span>
                <button (click)="updateOrderHistoryRecordPage(total_page)"  class="pagination-btn" *ngIf="(current_page + 3) <= total_page">{{ total_page }}</button>
                
                <button (click)="updateOrderHistoryRecordPage(current_page + 1)" *ngIf="(current_page + 1) <= total_page"> Next </button>

            </div>
    </div>

    </div>

</div>