
<div ngbDropdown class="d-inline-block drpdown-div">
    <button 
        type="button"
        class="btn btn-outline-primary dropdown-btn" 
        id="dropdownBasic1" 
        ngbDropdownToggle>
        <span *ngIf="!classificationFilter"> Classification </span>
        <span 
        [ngClass]="{'span-active': classificationFilter , 'span-placeholder': !classificationFilter}" 
        *ngIf="classificationFilter">
            {{ globalService.capitalizeFirstLetter(classificationFilter) }}
        </span>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="drpdown-list">
        <button 
        ngbDropdownItem (click)="setClassificationFilter('')">
            None
        </button>
        <button 
        *ngFor="let item of classificationFilterItems;"
        ngbDropdownItem 
        (click)="setClassificationFilter(item[1])">
            {{ item[0] }}
        </button>
    </div>
</div>