import { HostListener, Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  chartColors:Array<string> = ["#ed2938", "#ff8c01", "#ff7c8a", "#333333", "#9c9c9c"] 

  constructor(
    private http: HttpClient,
    public _snackBar: MatSnackBar
  ) { }

  numberWithCommas(x:any) {
      x = parseFloat(`${x}`).toFixed(2);
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  checkIfNaN(x:any){
    return isNaN(x) ? true : false;
  }

  addOneDayToEndDate(date:any){
    var newDate = new Date(`${date.year}-${date.month}-${date.day}`);
    newDate.setDate(newDate.getDate() + 1);
    return `${newDate.getFullYear()}-${newDate.getMonth()+1}-${newDate.getDate()}`;
  }
  
  formatStringToNgbDateStruct(strDate:string){
    
    strDate = strDate.replaceAll("/","-");
    strDate = strDate.replaceAll(" ","-");
    
    var dateArray = strDate.split("-");
    if (dateArray.length == 3) {
      return { year: parseInt(dateArray[0]) , month: parseInt(dateArray[1]), day: parseInt(dateArray[2]) } 
    }else{
      return "";
    }

  }

  formatNgbDateStructToString(ngDate:NgbDateStruct){
    var newDate = new Date(`${ngDate.year}-${ngDate.month}-${ngDate.day}`);
    var dateString = `${newDate.getFullYear()}`;
    dateString +=  (newDate.getMonth()+1) < 10 ? `-0${newDate.getMonth()+1}`:`-${newDate.getMonth()+1}`;
    dateString +=  (newDate.getDate()) < 10 ? `-0${newDate.getDate()}`:`-${newDate.getDate()}`;
    return dateString;
  }

  showSnackbar(message:string, action:string ){
    this._snackBar.open(message, "Ok", 
    {
      duration: 3000,
      verticalPosition: 'top'
    }
    );
  }

  getUserInformation(){
    if (localStorage.getItem("user")) {
      return JSON.parse(localStorage.getItem("user")!.toString()); 
    }else{
      return "";
    }
  }
  
  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    return {
      'height': window.innerHeight,
      'width': window.innerWidth
    }
  }
  isMobile(event? : any) {
    return window.innerWidth <= 600 ? true : false;
  }
  
  dummyTPSellOutData = [
    {
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
    {
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
    {
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
    {
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
    {
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
    {
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
    {
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
    {
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
    {
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
  ]
  dummyDealerSellOutData = [
    {
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
    {
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
    {
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
    {
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
    {
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
    {
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
    {
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
    {
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "name": "Jomar Montano",
      "classification": "DEALER"
    },
  ]
  dummyRetailerSellOutData = [
    {
      "retailer_name":"Retailer 1",
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "subscriber": "ARSN5623",
    },
    {
      "retailer_name":"Retailer 1",
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "subscriber": "ARSN5623",
    },
    {
      "retailer_name":"Retailer 1",
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "subscriber": "ARSN5623",
    },
    {
      "retailer_name":"Retailer 1",
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "subscriber": "ARSN5623",
    },
    {
      "retailer_name":"Retailer 1",
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "subscriber": "ARSN5623",
    },
    {
      "retailer_name":"Retailer 1",
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "subscriber": "ARSN5623",
    },
    {
      "retailer_name":"Retailer 1",
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "subscriber": "ARSN5623",
    },
    {
      "retailer_name":"Retailer 1",
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "subscriber": "ARSN5623",
    },
    {
      "retailer_name":"Retailer 1",
      "dealer_name":"Dealer 1",
      "tp_name":"Territorial Partner 1",
      "e_pins_denom": "sampleDemon300",
      "amount": "3200",
      "price": "3200",
      "date": "2023-06-09",
      "subscriber": "ARSN5623",
    },
  ]

  capitalizeFirstLetter(string:string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
