import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.css']
})
export class SummaryCardComponent implements OnInit{
  
  @Input() title: string = "";
  @Input() value: string = "";

  constructor(public globalsService: GlobalService){}

  ngOnInit(): void{
    this.value = this.value;
  }

}
