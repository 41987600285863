<div class="wrapper">
    
    <app-sidebar [page]="3"></app-sidebar>
    
    <div class="w-100">
        <app-top-bar></app-top-bar>

        <div class="card">
            
            <!-- title and export -->
            <div class="header">
                <div class="title">
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="#ef182e" width="30" height="30" viewBox="0 0 500 500"><path d="M421.84,78.16A243,243,0,1,0,78.16,421.84a243,243,0,0,0,343.68,0q8.79-8.79,16.54-18.29a25.23,25.23,0,1,0-35.31-36l0,0-.31.38a26,26,0,0,0-3,3.83A192.75,192.75,0,0,1,250,443C143.57,443,57,356.43,57,250S143.57,57,250,57s193,86.59,193,193c0,2,0,4-.1,6l-61.29-61.29A25,25,0,0,0,346.28,230l85.33,85.34,11.24,11.23a25,25,0,0,0,42.62-16.15A244.34,244.34,0,0,0,493,250,241.43,241.43,0,0,0,421.84,78.16Z"/><path d="M250,98.26a25,25,0,0,0-25,25v137.1l68.11,68.11a25,25,0,0,0,35.35-35.36L275,239.64V123.26A25,25,0,0,0,250,98.26Z"/></svg>
                    <h2>Purchase Order History</h2>  
                </div>

                <button class="filter-toggle-btn export-btn" (click)="showFilter(true)">
                    <svg id="Layer_1" data-name="Layer 1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><path d="M34.4,104H62.86v7.73a40,40,0,0,0,80,0V104H465.6a25,25,0,1,0,0-50H142.86V46.26a40,40,0,0,0-80,0V54H34.4a25,25,0,0,0,0,50Z"/><path d="M465.6,396H142.86v-7.74a40,40,0,0,0-80,0V396H34.4a25,25,0,1,0,0,50H62.86v7.73a40,40,0,0,0,80,0V446H465.6a25,25,0,0,0,0-50Z"/><path d="M465.6,225H437.14v-7.73a40,40,0,1,0-80,0V225H34.4a25,25,0,0,0,0,50H357.14v7.73a40,40,0,0,0,80,0V275H465.6a25,25,0,0,0,0-50Z"/></svg>
                </button>
                
                <button (click)="exportPurchaseOrderRecord()" class="export-btn">
                    <svg id="Layer_1" data-name="Layer 1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><defs><style>.cls-1{fill:none;}</style></defs><path class="cls-1" d="M106.8,134.48H357.4a24.52,24.52,0,1,0,0-49H106.8a24.52,24.52,0,0,0,0,49Z"/><path d="M106.8,382.36a24.52,24.52,0,0,1,0-49h70.63A169.35,169.35,0,0,1,201,258.42H106.8a24.52,24.52,0,0,1,0-49H245a170.42,170.42,0,0,1,211.52,5.29V67.15A57.66,57.66,0,0,0,398.85,9.5H65.35A57.66,57.66,0,0,0,7.69,67.15v333.5a57.66,57.66,0,0,0,57.66,57.66H219.58A169.93,169.93,0,0,1,181,382.36Zm0-296.91H357.4a24.52,24.52,0,1,1,0,49H106.8a24.52,24.52,0,0,1,0-49Z"/><path d="M456.51,250.26A144.9,144.9,0,0,0,203,333.32q-.51,6.07-.52,12.29a144.9,144.9,0,1,0,254-95.35ZM422.1,383a24.52,24.52,0,1,1-49,0V355.53l-26.82,26.83L314.58,414a24.52,24.52,0,0,1-37.23-31.67,24.84,24.84,0,0,1,2.55-3l46-46,14.27-14.27H312.79a24.52,24.52,0,1,1,0-49H422.1Z"/></svg>
                    <span>Export CSV</span>
                </button>
            </div>
            
            <div class="filter-div" id="filter_div">
                
                <!-- filters 1-->
                <div class="filter-list">

                    <!-- filter title  -->
                    <div class="filter-title">
                        <div class="title">
                            <svg id="Layer_1" data-name="Layer 1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><path d="M34.4,104H62.86v7.73a40,40,0,0,0,80,0V104H465.6a25,25,0,1,0,0-50H142.86V46.26a40,40,0,0,0-80,0V54H34.4a25,25,0,0,0,0,50Z"/><path d="M465.6,396H142.86v-7.74a40,40,0,0,0-80,0V396H34.4a25,25,0,1,0,0,50H62.86v7.73a40,40,0,0,0,80,0V446H465.6a25,25,0,0,0,0-50Z"/><path d="M465.6,225H437.14v-7.73a40,40,0,1,0-80,0V225H34.4a25,25,0,0,0,0,50H357.14v7.73a40,40,0,0,0,80,0V275H465.6a25,25,0,0,0,0-50Z"/></svg>
                            <h3>Filters</h3>
                        </div>
                        <div class="close-filter" (click)="showFilter(false)">
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><path d="M285.36,250,470.91,64.44a25,25,0,0,0-35.35-35.35L250,214.64,64.44,29.09A25,25,0,0,0,29.09,64.44L214.64,250,29.09,435.56a25,25,0,0,0,35.35,35.35L250,285.36,435.56,470.91a25,25,0,0,0,35.35-35.35Z"/></svg>
                        </div>
                    </div>
                
                    <!-- Territorial Partner Username filter -->
                    <div ngbDropdown class="d-inline-block">
                        <input 
                        type="text" 
                        class="form-control input-dropdown" 
                        id="dropdownTpUsernamesFilter" 
                        placeholder="Territorial Partner"
                        [(ngModel)] = "tpNameFilter"
                        (ngModelChange)="tpNameFilterChange()"
                        (focusout)="setOrderRecordFilter()"
                        ngbDropdownToggle>
                        <div ngbDropdownMenu aria-labelledby="dropdownTpUsernamesFilter" class="dropdown-filter-list">
                            <button ngbDropdownItem *ngFor="let tp_username of tp_usernames; index as i" (click)="setTpNameFilter(tp_username.tp_username)">
                                {{tp_username.tp_username}}
                            </button>
                        </div>
                    </div>
            
                    <!-- Payment Status filter -->
                    <div ngbDropdown class="d-inline-block">
                        <button type="button" class="btn btn-outline-primary dropdown-btn" id="dropdownBasic1" ngbDropdownToggle>
                            <span *ngIf="!statusFilter"> Status filter </span>
                            <span 
                            [ngClass]="{'span-active': statusFilter , 'span-placeholder': !statusFilter}" 
                            *ngIf="statusFilter">
                                {{statusFilter == '1' ? "Yes" : 'No'}}
                            </span>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="setStatusFilter('')">None</button>
                            <button ngbDropdownItem (click)="setStatusFilter('1')">Yes</button>
                            <button ngbDropdownItem  (click)="setStatusFilter('0')">No</button>
                        </div>
                    </div>
                    
                    <!-- from filter -->
                    <form>
                        <div class="col-12">
                            <div class="input-group form-control date-picker">
                                <input
                                    class="form-control"
                                    name="fromDateFilter"
                                    placeholder="YYYY-MM-DD"
                                    [(ngModel)]="fromDateFilter"
                                    [maxDate]="maxDateFilter"
                                    (dateSelect)="updateAvailableDateRange()"
                                    (change)="updateAvailableDateRange()"
                                    ngbDatepicker
                                    #from="ngbDatepicker"
                                />
                                <span>From</span>
                                <svg (click)="from.toggle()" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 500 500"><path d="M436.68,62.9H397.52V25.1a15,15,0,0,0-15-15H351.25a15,15,0,0,0-15,15V62.9H163.75V25.1a15,15,0,0,0-15-15H117.48a15,15,0,0,0-15,15V62.9H63.32a50,50,0,0,0-50,50v327a50,50,0,0,0,50,50H436.68a50,50,0,0,0,50-50v-327A50,50,0,0,0,436.68,62.9ZM443.16,425a25,25,0,0,1-25,25H81.84a25,25,0,0,1-25-25V156.85H443.16Z"/><rect x="102.48" y="215.12" width="61.28" height="61.28" rx="15"/><rect x="336.25" y="215.12" width="61.28" height="61.28" rx="15"/><rect x="219.36" y="215.12" width="61.28" height="61.28" rx="15"/><rect x="102.48" y="322.35" width="61.28" height="61.28" rx="15"/><rect x="336.25" y="322.35" width="61.28" height="61.28" rx="15"/><rect x="219.36" y="322.35" width="61.28" height="61.28" rx="15"/></svg>
                            
                            </div>
                        </div>
                    </form>
                    
                    <!-- to filter -->
                    <form>
                        <div class="col-12">
                            <div class="input-group form-control date-picker">
                                <input
                                    class="form-control"
                                    name="toDateFilter"
                                    placeholder="YYYY-MM-DD"
                                    [(ngModel)]="toDateFilter"
                                    [minDate]="minDateFilter"
                                    (dateSelect)="updateAvailableDateRange()"
                                    (change)="updateAvailableDateRange()"
                                    ngbDatepicker
                                    #to="ngbDatepicker"
                                />
                                <span>To</span>
                                <svg (click)="to.toggle()" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 500 500"><path d="M436.68,62.9H397.52V25.1a15,15,0,0,0-15-15H351.25a15,15,0,0,0-15,15V62.9H163.75V25.1a15,15,0,0,0-15-15H117.48a15,15,0,0,0-15,15V62.9H63.32a50,50,0,0,0-50,50v327a50,50,0,0,0,50,50H436.68a50,50,0,0,0,50-50v-327A50,50,0,0,0,436.68,62.9ZM443.16,425a25,25,0,0,1-25,25H81.84a25,25,0,0,1-25-25V156.85H443.16Z"/><rect x="102.48" y="215.12" width="61.28" height="61.28" rx="15"/><rect x="336.25" y="215.12" width="61.28" height="61.28" rx="15"/><rect x="219.36" y="215.12" width="61.28" height="61.28" rx="15"/><rect x="102.48" y="322.35" width="61.28" height="61.28" rx="15"/><rect x="336.25" y="322.35" width="61.28" height="61.28" rx="15"/><rect x="219.36" y="322.35" width="61.28" height="61.28" rx="15"/></svg>
                            
                            </div>
                        </div>
                    </form>
            
                </div>
                
                <!-- filters 2-->
                <div class="filter-list">
                    <!-- Seller Username filter -->
                    <div ngbDropdown class="d-inline-block">
                        <input 
                        type="text" 
                        class="form-control input-dropdown" 
                        id="dropdownSellerUsernamesFilter" 
                        placeholder="Seller Username"
                        [(ngModel)] = "sellerUsernameFilter"
                        (ngModelChange)="sellerUsernameFilterChange()"
                        (focusout)="setOrderRecordFilter()"
                        ngbDropdownToggle>
                        <div ngbDropdownMenu aria-labelledby="dropdownSellerUsernamesFilter" class="dropdown-filter-list">
                            <button ngbDropdownItem *ngFor="let seller_username of seller_usernames; index as i" (click)="setSellerUsernameFilter(seller_username.username)">
                                {{seller_username.username}}
                            </button>
                        </div>
                    </div>

                    <input type="text" class="form-control search-input" id="searchInput" placeholder="Search" [(ngModel)]="temp_searchValue">
            
                    <div class="search-n-clear">
                        <button class="search-btn" (click)="searchOrder()">
                            <div>
                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 500 500"><path d="M479,451,374.78,346.74a204.87,204.87,0,0,0,50.33-134.67c0-113.44-92.29-205.73-205.73-205.73S13.65,98.63,13.65,212.07s92.29,205.72,205.73,205.72a204.61,204.61,0,0,0,118.26-37.48l106,106A25,25,0,0,0,479,451ZM63.65,212.07c0-85.87,69.86-155.73,155.73-155.73S375.11,126.2,375.11,212.07a155.27,155.27,0,0,1-47.35,111.7,25.15,25.15,0,0,0-9.9,8.83,155,155,0,0,1-98.48,35.19C133.51,367.79,63.65,297.93,63.65,212.07Z"/></svg>
                                <span>Search</span>
                            </div>
                        </button>
                        
                        <button (click)="clearSearchOrder()" type="button" class="btn btn-outline-primary">
                            <span>Clear</span>
                        </button>
                    </div>
                    
                </div>
                
            </div>

        </div>

            <!-- data table -->
        <div class="table-div">
            <table matSort (matSortChange)="sortData($event)" class="table table-striped">
                <thead>
                    <tr>
                        <th 
                        scope="col"
                        mat-sort-header="tp_username">
                            Territorial Partner
                        </th>
                        
                        <th 
                        scope="col"
                        mat-sort-header="serial_number">
                            Serial No.
                        </th>
                        
                        <th 
                        scope="col"
                        mat-sort-header="seller_username">
                            Seller Username
                        </th>

                        <th 
                        scope="col"
                        mat-sort-header="seller_name">
                            Seller Name
                        </th>
                    
                        <th 
                        scope="col"
                        mat-sort-header="code">
                            Code
                        </th>
                        
                        <th 
                        scope="col"
                        mat-sort-header="code">
                            Price
                        </th>
                        
                        <th 
                        scope="col"
                        mat-sort-header="transaction_date">
                            Date
                        </th>
                        
                        <th 
                        scope="col">
                            Time
                        </th>
                        
                        <th 
                        scope="col"
                        mat-sort-header="paid">
                            Paid Status
                        </th>
                    
                    </tr>
                </thead>
                <tbody *ngIf="!isLoading && !message" >
                    <tr *ngFor="let orderHistoryRecord of orderHistoryRecords; index as i">
                        <td>{{ orderHistoryRecord.tp_username }}</td>
                        <td>{{ orderHistoryRecord.serial_number }}</td>
                        <td>{{ orderHistoryRecord.seller_username }}</td>
                        <td>{{ orderHistoryRecord.seller_name }}</td>
                        <td>{{ orderHistoryRecord.code }} </td>
                        <td>{{ globalService.checkIfNaN(orderHistoryRecord.code) ?  orderHistoryRecord.code : "₱"+globalService.numberWithCommas(orderHistoryRecord.code) }}</td>
                        <td>{{ orderHistoryRecord.transaction_date }}</td>
                        <td>{{ orderHistoryRecord.transaction_time }}</td>
                        <td>
                            <span [ngClass]="{'paid': orderHistoryRecord.paid == 1, 'not-paid': orderHistoryRecord.paid==0}">
                                {{ orderHistoryRecord.paid == 0 ? "NO" : "YES" }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            
            <div *ngIf="message && !isLoading" class="m-auto error-message"><span>{{message}}</span></div>


            <div *ngIf="isLoading" class="table-spinner-div">
                <span class="sr-only"> <div class="spinner-border" role="status"> </div> Loading...</span>
            </div>
    </div>
       
    
            
    <div 
    class="pagination"
    *ngIf="!isTotalLoading && !isTotalLoading">
        
        <button (click)="updateOrderHistoryRecordPage(current_page - 1)" *ngIf="(current_page - 1) >= 1" > 
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 500 500"><path d="M151.27,214.64,341.41,24.51a25,25,0,1,1,35.35,35.36L186.63,250,376.76,440.13a25,25,0,1,1-35.35,35.36L151.27,285.36,115.92,250Z"/></svg>
            <span>Prev</span>
        </button>

        <button (click)="updateOrderHistoryRecordPage(1)"  class="pagination-btn"  *ngIf="(current_page - 3) >= 1"> 1 </button>
        <span *ngIf="(current_page - 3) >= 1"n> ... </span>
        
        <button (click)="updateOrderHistoryRecordPage(current_page - 2)" class="pagination-btn" *ngIf="(current_page - 2) >= 1" >{{ current_page - 2 }}</button>
        <button (click)="updateOrderHistoryRecordPage(current_page - 1)" class="pagination-btn" *ngIf="(current_page - 1) >= 1" >{{ current_page - 1 }}</button>
        <button >{{ current_page }}</button>
        <button (click)="updateOrderHistoryRecordPage(current_page + 1)" class="pagination-btn" *ngIf="(current_page + 1) <= total_page" >{{ current_page + 1 }}</button>
        <button (click)="updateOrderHistoryRecordPage(current_page + 2)" class="pagination-btn" *ngIf="(current_page + 2) <= total_page" >{{ current_page + 2 }}</button>
        
        <span *ngIf="(current_page + 3) <= total_page"> ... </span>
        <button (click)="updateOrderHistoryRecordPage(total_page)"  class="pagination-btn" *ngIf="(current_page + 3) <= total_page">{{ total_page }}</button>
        
        <button (click)="updateOrderHistoryRecordPage(current_page + 1)" *ngIf="(current_page + 1) <= total_page"> 
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 500 500"><path d="M348.73,214.64,158.59,24.51a25,25,0,1,0-35.35,35.36L313.37,250,123.24,440.13a25,25,0,1,0,35.35,35.36L348.73,285.36,384.08,250Z"/></svg>
            <span>Next</span>
        </button>

    </div>

    <div 
    *ngIf="!isLoading && isTotalLoading"
    class="pagination">
        Loading pages...
    </div>

</div>