import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToggleSidebarService {

  private toggle = new Subject<boolean>();

  public $toggle = this.toggle.asObservable();

  constructor() { }

  toggleSideBar(toggle_flg:boolean){
    this.toggle.next(toggle_flg);
    console.log(this.toggle);
  }
}
