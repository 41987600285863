import { Component } from '@angular/core';
import { GlobalService } from './../global.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent {

  userDetails:any;

  constructor(
    public globalService: GlobalService
    ) {}
  
  ngOnInit(): void{
    this.userDetails = this.globalService.getUserInformation();
  }



}
