
<div class="container">
    <!-- Search filter -->
    <input type="text" class="form-control search-input" id="searchInput" placeholder="Search" [(ngModel)]="temp_searchValue">                  
    
    <button class="search-btn" (click)="searchOrder()">
        <app-svg 
            color="white"
            width="20px"
            height="20px"
            src="assets/svg/search.svg">
        </app-svg>
        <span>Search</span>
    </button>

</div>