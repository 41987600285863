import { Component } from '@angular/core';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';
import { GlobalService } from './../../global.service';
import { ToggleSidebarService } from './../../sidebar/toggle-sidebar.service';

@Component({
  selector: 'app-notif-and-account',
  templateUrl: './notif-and-account.component.html',
  styleUrls: ['./notif-and-account.component.css']
})
export class NotifAndAccountComponent {
  
  userDetails:any;

  constructor(
    private router:Router,
    private authService: AuthService,
    public globalService: GlobalService,
    public toggleSidebarService: ToggleSidebarService
  ){}
  
  ngOnInit(): void{
    this.userDetails = this.globalService.getUserInformation();
  }

  toggleSideBar() {
    this.toggleSidebarService.toggleSideBar(true);
  }

  async logout(){
    console.log("logout")
    if(await this.authService.sendLogoutRquest()){
      this.router.navigateByUrl('/login');
    }
  }

}
