<div class="wrapper">
    
    <app-sidebar [page]="7"></app-sidebar>
    
    <div class="w-100">
        <app-top-bar></app-top-bar>

        
        <div class="card">

                <!-- PAGE TITLE, TITLE, FILTER MOBILE BUTTON -->
                <div class="header">
                    
                    <!-- ICON & TITLE -->
                    <div class="title">
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="#ef182e" width="30" height="30" viewBox="0 0 500 500"><path d="M430.24,351H142.5L75.33,79.11H32a25,25,0,0,1,0-50h82.46L181.65,301H392.29L443.9,116.11a25,25,0,1,1,48.16,13.44Z"/><path d="M378.18,184l-72.3,77.14a25,25,0,0,1-36.48,0L197.09,184a25,25,0,0,1,18.24-42.1h26.24V54.11a25,25,0,0,1,25-25H308.7a25,25,0,0,1,25,25v87.82h26.24A25,25,0,0,1,378.18,184Z"/><circle cx="198.57" cy="421.05" r="49.83"/><circle cx="380.71" cy="421.05" r="49.83"/></svg>
                        <h2>Sell-In</h2>              
                    </div>
        
                    <div class="header-button-div">

                        <!-- FILTER TOGGLE BUTTON FOR MOBILE -->
                        <button class="filter-toggle-btn export-btn" (click)="showFilter(true)">
                            <svg id="Layer_1" data-name="Layer 1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><path d="M34.4,104H62.86v7.73a40,40,0,0,0,80,0V104H465.6a25,25,0,1,0,0-50H142.86V46.26a40,40,0,0,0-80,0V54H34.4a25,25,0,0,0,0,50Z"/><path d="M465.6,396H142.86v-7.74a40,40,0,0,0-80,0V396H34.4a25,25,0,1,0,0,50H62.86v7.73a40,40,0,0,0,80,0V446H465.6a25,25,0,0,0,0-50Z"/><path d="M465.6,225H437.14v-7.73a40,40,0,1,0-80,0V225H34.4a25,25,0,0,0,0,50H357.14v7.73a40,40,0,0,0,80,0V275H465.6a25,25,0,0,0,0-50Z"/></svg>
                        </button>
            
                        <!-- EXPORT BUTTON -->
                        <button (click)="exportSellInRecord()">
                            <svg id="Layer_1" data-name="Layer 1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><defs><style>.cls-1{fill:none;}</style></defs><path class="cls-1" d="M106.8,134.48H357.4a24.52,24.52,0,1,0,0-49H106.8a24.52,24.52,0,0,0,0,49Z"/><path d="M106.8,382.36a24.52,24.52,0,0,1,0-49h70.63A169.35,169.35,0,0,1,201,258.42H106.8a24.52,24.52,0,0,1,0-49H245a170.42,170.42,0,0,1,211.52,5.29V67.15A57.66,57.66,0,0,0,398.85,9.5H65.35A57.66,57.66,0,0,0,7.69,67.15v333.5a57.66,57.66,0,0,0,57.66,57.66H219.58A169.93,169.93,0,0,1,181,382.36Zm0-296.91H357.4a24.52,24.52,0,1,1,0,49H106.8a24.52,24.52,0,0,1,0-49Z"/><path d="M456.51,250.26A144.9,144.9,0,0,0,203,333.32q-.51,6.07-.52,12.29a144.9,144.9,0,1,0,254-95.35ZM422.1,383a24.52,24.52,0,1,1-49,0V355.53l-26.82,26.83L314.58,414a24.52,24.52,0,0,1-37.23-31.67,24.84,24.84,0,0,1,2.55-3l46-46,14.27-14.27H312.79a24.52,24.52,0,1,1,0-49H422.1Z"/></svg>
                            <span>Export CSV</span>
                        </button>

                    </div>
    
                </div>
                
                <div class="filter-div" id="filter_div">
                    <!-- filter 1 -->
                    <div class="filter-list">
                        <!-- mobile filter button -->
                        <div class="filter-title">
                            <div class="title"> 
                                <svg id="Layer_1" data-name="Layer 1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><path d="M34.4,104H62.86v7.73a40,40,0,0,0,80,0V104H465.6a25,25,0,1,0,0-50H142.86V46.26a40,40,0,0,0-80,0V54H34.4a25,25,0,0,0,0,50Z"/><path d="M465.6,396H142.86v-7.74a40,40,0,0,0-80,0V396H34.4a25,25,0,1,0,0,50H62.86v7.73a40,40,0,0,0,80,0V446H465.6a25,25,0,0,0,0-50Z"/><path d="M465.6,225H437.14v-7.73a40,40,0,1,0-80,0V225H34.4a25,25,0,0,0,0,50H357.14v7.73a40,40,0,0,0,80,0V275H465.6a25,25,0,0,0,0-50Z"/></svg>
                                <h3>Filters</h3>
                            </div>
                            <div class="close-filter" (click)="showFilter(false)">
                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><path d="M285.36,250,470.91,64.44a25,25,0,0,0-35.35-35.35L250,214.64,64.44,29.09A25,25,0,0,0,29.09,64.44L214.64,250,29.09,435.56a25,25,0,0,0,35.35,35.35L250,285.36,435.56,470.91a25,25,0,0,0,35.35-35.35Z"/></svg>
                            </div>
                        </div>
                        <!-- Territorial Partner Username filter -->
                        <app-tp-username-filter
                            (updateTpNameFilter)="updateTpNameFilter($event)"
                        ></app-tp-username-filter>
                    
                        <app-date-range-picker
                        (updateDateRangeFilter)="updateDateRangeFilter($event)"
                        ></app-date-range-picker>

                    </div>

                    <!-- filters 2-->
                    <div class="filter-list">

                        <!-- WEB SEARCH FILTER -->
                        <app-filter-search-bar
                            *ngIf="!isMobile"
                            [searchValue] = "searchValue"
                            (updateSearchValue) = "updateSearchValue($event)"
                        ></app-filter-search-bar>
                        
                        <!--  clear button -->
                        <div class="search-n-clear">
                            <button 
                                *ngIf="!isMobile"
                                (click)="clearAllFilters()" 
                                type="button" 
                                class="btn btn-outline-primary">
                                <span>Clear</span>
                            </button>
                        </div>
                        
                    </div>
                </div>
                
                <!-- MOBILE SEARCH FILTER -->
                <app-filter-search-bar
                    *ngIf="isMobile"
                    [searchValue] = "searchValue"
                    (updateSearchValue) = "updateSearchValue($event)"
                ></app-filter-search-bar>

                <!-- mobile clear button -->
                <div class="search-n-clear">
                    <button 
                        *ngIf="isMobile"
                        (click)="clearAllFilters()" 
                        type="button" 
                        class="btn btn-outline-primary">
                        <span>Clear</span>
                    </button>
                </div>
                
                <!-- TAB MENU -->
                <div class="tab-div">
                    <button type="button" class="btn btn-outline-primary tab-button" [ngClass]="{'active-tab': currentTab === 'tp'}" (click)="selectTab('tp')">
                        <span>Territorial Partner</span>
                    </button>
                    <button type="button" class="btn btn-outline-primary tab-button" [ngClass]="{'active-tab': currentTab === 'dealer'}" (click)="selectTab('dealer')">
                        <span>Dealer</span>
                    </button>
                    <button type="button" class="btn btn-outline-primary tab-button active-tab" [ngClass]="{'active-tab': currentTab === 'retailer'}" (click)="selectTab('retailer')">
                        <span>Retailer</span>
                    </button>
                </div>

                <!-- SORT MENU FOR MOBILE -->
                <div
                *ngIf="isMobile">
                    <app-mobile-table-sort
                    (updateSort)="updateSort($event)"
                    [sortItems]="updateSortMenuItem()">
                    </app-mobile-table-sort>
                </div>
        </div>

        
        <app-tp-sell-in 
            *ngIf="currentTab == 'tp' " 
            [sellInData]="sellInData"
            [message]="message"
            (updateTpNameFilter)="updateSort($event)"
            [isLoading]="isLoading">
        </app-tp-sell-in>
        
        <app-dealer-sell-in 
            *ngIf="currentTab == 'dealer' " 
            [sellInData]="sellInData"
            [message]="message"
            (updateTpNameFilter)="updateSort($event)"
            [isLoading]="isLoading">
        </app-dealer-sell-in>
        
        <app-retailer-sell-in 
            *ngIf="currentTab == 'retailer' "
            [sellInData]="sellInData"
            [message]="message"
            (updateTpNameFilter)="updateSort($event)"
            [isLoading]="isLoading">
        </app-retailer-sell-in>

        <app-pagination
            *ngIf="!(isTotalLoading && isLoading)"
            [isLoading]="isTotalLoading"
            (onUpdatePage)="updateSellInRecordPage($event)"
            [current_page]="current_page"
            [total_page]="total_page"
        ></app-pagination>
       

    </div>

</div>