<div class="card">
    <h1>
        {{value}}
    </h1>
    
    <div class="card-title">
        
        <h3>{{title}}</h3>

        <!-- Total Revenue svg -->
        <div class="svg-div" *ngIf="(title == 'Total Revenue')">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" viewBox="0 0 500 500"><rect x="176.93" y="10.89" width="146.13" height="478.23" rx="40"/><rect x="5.66" y="232.28" width="146.13" height="256.84" rx="40"/><rect x="348.21" y="125.04" width="146.13" height="364.07" rx="40"/></svg>
        </div>
        
        <!-- Monthly Sales svg -->
        <div class="svg-div" *ngIf="(title == 'Monthly Sales')">
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" viewBox="0 0 500 500"><path d="M436.68,62.9H397.52V25.1a15,15,0,0,0-15-15H351.25a15,15,0,0,0-15,15V62.9H163.75V25.1a15,15,0,0,0-15-15H117.48a15,15,0,0,0-15,15V62.9H63.32a50,50,0,0,0-50,50v327a50,50,0,0,0,50,50H436.68a50,50,0,0,0,50-50v-327A50,50,0,0,0,436.68,62.9ZM443.16,425a25,25,0,0,1-25,25H81.84a25,25,0,0,1-25-25V156.85H443.16Z"/><rect x="102.48" y="215.12" width="61.28" height="61.28" rx="15"/><rect x="336.25" y="215.12" width="61.28" height="61.28" rx="15"/><rect x="219.36" y="215.12" width="61.28" height="61.28" rx="15"/><rect x="102.48" y="322.35" width="61.28" height="61.28" rx="15"/><rect x="336.25" y="322.35" width="61.28" height="61.28" rx="15"/><rect x="219.36" y="322.35" width="61.28" height="61.28" rx="15"/></svg>
        </div>
    
        <!-- Weekly Sales -->
        <div class="svg-div" *ngIf="(title == 'Weekly Sales')">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" viewBox="0 0 500 500"><path d="M81.67,23.21H30.46C15.66,23.21,3.65,36,3.65,51.67V448.33c0,15.72,12,28.46,26.81,28.46H81.67c14.8,0,26.8-12.74,26.8-28.46V51.67C108.47,36,96.47,23.21,81.67,23.21Z"/><path d="M211,23.21H159.75c-14.8,0-26.81,12.74-26.81,28.46V448.33c0,15.72,12,28.46,26.81,28.46H211c14.8,0,26.8-12.74,26.8-28.46V51.67C237.76,36,225.76,23.21,211,23.21Z"/><path d="M340.25,23.21H289c-14.8,0-26.81,12.74-26.81,28.46V448.33c0,15.72,12,28.46,26.81,28.46h51.21c14.8,0,26.81-12.74,26.81-28.46V51.67C367.06,36,355.05,23.21,340.25,23.21Z"/><path d="M469.54,23.21H418.33c-14.8,0-26.8,12.74-26.8,28.46V448.33c0,15.72,12,28.46,26.8,28.46h51.21c14.8,0,26.81-12.74,26.81-28.46V51.67C496.35,36,484.34,23.21,469.54,23.21Z"/></svg>
        </div>

        
    </div>
</div>