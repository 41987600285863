import { Component, Input } from '@angular/core';
import { ToggleSidebarService } from './../sidebar/toggle-sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {
  @Input() page:number = 1;
  
  constructor(
    public toggleSidebarService: ToggleSidebarService
  ){}
  
  ngOnInit(){
    this.toggleSidebarService.$toggle.subscribe(val => {
      let sidebar = document.getElementById("side_bar") as HTMLElement;
      if (sidebar) {
        if (val) {
            sidebar.style.display = "flex";
          }else{
            sidebar.style.display = "none";
          }
      }
    });
  }

  closeSidebar(){
    this.toggleSidebarService.toggleSideBar(false);
  }

}
