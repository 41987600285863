<div class="wrapper">
    
    <app-sidebar [page]="1"></app-sidebar>
    
    <div class="w-100">

        <app-top-bar></app-top-bar>
        
        <div class="dashboard">
            <div class="summary-graph">

                <div class="summary">
                    <app-summary-card 
                    [title]="'Total Revenue'"
                    [value]="total_revenue">
                    </app-summary-card>
        
                    <app-summary-card 
                    [title]="'Monthly Sales'"
                    [value]="monthly_sales">
                    </app-summary-card>
        
                    <app-summary-card 
                    [title]="'Weekly Sales'"
                    [value]="weekly_sales">
                    </app-summary-card>
                </div>

                <app-sales-overview></app-sales-overview>
            
            </div>
            
            <app-transaction-list></app-transaction-list>
        
        </div>

    </div>

</div>