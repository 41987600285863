import { Component, ViewChild} from '@angular/core';
import { GlobalService } from '../global.service';
import { CignalService } from '../cignal.service';
import { Router } from '@angular/router';
import {Sort} from '@angular/material/sort';
import { FilterSearchBarComponent } from '../reusables/filter-search-bar/filter-search-bar.component';
import { TpUsernameFilterComponent } from '../reusables/tp-username-filter/tp-username-filter.component';
import { DateRangePickerComponent } from '../reusables/date-range-picker/date-range-picker.component';
import { MobileTableSortComponent } from '../reusables/mobile-table-sort/mobile-table-sort.component';
import { SellInService } from '../services/sell-in/sell-in.service';

@Component({
  selector: 'app-sell-in',
  templateUrl: './sell-in.component.html',
  styleUrls: ['./sell-in.component.css'],
})
export class SellInComponent {

  @ViewChild(FilterSearchBarComponent) 
  private SearchBar!:FilterSearchBarComponent;
  @ViewChild(TpUsernameFilterComponent) 
  private tpUsernameFilter!:TpUsernameFilterComponent;
  @ViewChild(DateRangePickerComponent) 
  private dateRangePicker!:DateRangePickerComponent;
  @ViewChild(MobileTableSortComponent) 
  private mobileTableSortComponent!:MobileTableSortComponent;

  constructor(
    private cignalService: CignalService,
    public globalService: GlobalService,
    private router: Router,
    private sellInService: SellInService
  ){}
  isMobile : boolean = false;
  sellInData : Array<any>= [];
  currentTab = "tp";
  requestId = 0;
  requestTotalId = 0;

  isLoading : boolean = false;
  isTotalLoading : boolean = false;
  message : any;

  sort_by : string = ""
  sort_order : string = ""


  ngOnInit(){
    this.isMobile = this.globalService.isMobile();
    this.loadInitialResult();
  }

  async loadInitialResult(){
    this.isTotalLoading = true;
    await this.getSellInData();
    await this.getSellInDataTotal();
  }  

  updateSortMenuItem(){
    switch (this.currentTab) {
      case 'tp':
        return [
          ['Territorial Partner','tp_username'],
          ['e-Pins Denoms','code'],
          ['Amount','amount'],
          ['Date','create_date'],
        ];

        break;
      case 'dealer':
        return [
          ['Dealer','dealer_username'],
          ['Territorial Partner','tp_username'],
          ['e-Pins Denoms','code'],
          ['Amount','amount'],
          ['Date','create_date'],
        ];
        
        break;
      case 'retailer':
        return [
          ['Retailer','retailer_username'],
          ['Dealer/Territorial Partner','tp_dealer_username'],
          ['e-Pins Denoms','code'],
          ['Amount','amount'],
          ['Date','create_date'],
        ];
        
        break;
      default:
        return [];

        break;
    }
  }

  // PAGINATION
  current_page = 1;
  total_page = 1;
  updateSellInRecordPage(page:number){
    this.current_page = page;
    this.getSellInData();
  }

  // SEARCH FILTER
  searchValue:string = "";
  updateSearchValue(keyWord:string){
    this.searchValue = keyWord;
    this.clearFilters();
    this.current_page = 1;
    this.loadInitialResult();
  }

  // SELL-IN FILTER
  sellInFilter : any = {};
  
  // TP NAME FILTER
  updateTpNameFilter(newTpName:string){
    this.clearSearch();
    this.sellInFilter.tpNameFilter = newTpName.trim();
    if (!this.sellInFilter.tpNameFilter) {
      delete this.sellInFilter.tpNameFilter;  
    }
    this.current_page = 1;
    this.loadInitialResult();
  }
  
  // DATE RANGE FILTER
  updateDateRangeFilter(newDateRangeFilter:any){
    this.clearSearch();
    delete this.sellInFilter.start_date;
    delete this.sellInFilter.end_date;

    if (typeof newDateRangeFilter.start_date === 'object') {
      this.sellInFilter.start_date = this.globalService.formatNgbDateStructToString(newDateRangeFilter.start_date);      
    }
    if (typeof newDateRangeFilter.end_date === 'object') {
      this.sellInFilter.end_date = this.globalService.addOneDayToEndDate(newDateRangeFilter.end_date);      
    }
    this.current_page = 1;
    this.loadInitialResult();
  }

  
  showFilter(val:boolean){
    let sidebar = document.getElementById("filter_div") as HTMLElement;
    if (sidebar) {
      var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      console.log(width);
      if (width < 600) {
        if (val) {
          sidebar.style.display = "flex";
        }else{
          sidebar.style.display = "none";
        } 
      }
    }
  }

  formatFilterJson(filterJson : any, limit : number, offset: number,sort_by: string,sort_order:string){
    var filter : any = {};
    if (filterJson.tpNameFilter) { 
      filter.tp_username = filterJson.tpNameFilter;
    }
    if (filterJson.end_date) { 
      filter.to_date = filterJson.end_date;
    }
    if (filterJson.start_date) {
      filter.from_date = filterJson.start_date;
    }
    if (sort_order && sort_by) {
      filter.sort_order = sort_order;
      filter.sort_by = sort_by;
    }
    
    
    filter.limit = limit;
    filter.offset = offset;

    return filter;
  }

  // Clear
  clearFilters(){
    this.tpUsernameFilter.clearTpNameFilter();
    this.dateRangePicker.clearDateRange();
    this.sellInFilter = {};
  }
  clearSearch(){
    this.SearchBar.clearSearch();
    this.searchValue = "";
  }
  clearAllFilters(){
    this.clearSearch();
    this.clearFilters();
    this.current_page = 1;
    this.loadInitialResult();
  }

  // Sort
  updateSort(sort:Sort){
    if (sort.direction) {
      this.sort_order = sort.direction;
      this.sort_by = sort.active;
    }else{
      this.sort_order = "";
      this.sort_by = "";
    }
    this.getSellInData();
  }

  // Export
  async exportSellInRecord(){
  this.message = "";
  // get sales record by search
  if (this.searchValue) {
    switch (this.currentTab) {
      case 'tp':
        var result = await this.cignalService.exportSellInTpByTerms(this.searchValue);
        break;
    
      case 'dealer':
        var result = await this.cignalService.exportSellInDealerByTerms(this.searchValue);
        break;
          
      case 'retailer':
        var result = await this.cignalService.exportSellInRetailerByTerms(this.searchValue);
        break;
      default:
        result = {success: false, message:`there is no ${this.currentTab} tab.`};
        break;
    }
  }
  // get sales record by filter
  else if(Object.keys(this.sellInFilter).length > 0){
    
    var jsonFilter = this.formatFilterJson(this.sellInFilter, 0, 0, '', '');
    
    switch (this.currentTab) {
      case 'tp':
        var result = await this.cignalService.exportSellInTpByCriteria(jsonFilter);
        break;
    
      case 'dealer':
        var result = await this.cignalService.exportSellInDealerByCriteria(jsonFilter);
        break;
          
      case 'retailer':
        var result = await this.cignalService.exportSellInRetailerByCriteria(jsonFilter);
        break;
      default:
        result = {success: false, message:`there is no ${this.currentTab} tab.`};
        break;
    }
    // get all sales record

  }
  else{
    switch (this.currentTab) {
      case 'tp':
        var result = await this.cignalService.exportSellInTp();
        break;
    
      case 'dealer':
        var result = await this.cignalService.exportSellInDealer();
        break;
          
      case 'retailer':
        var result = await this.cignalService.exportSellInRetailer();
        break;
      default:
        result = {success: false, message:`there is no ${this.currentTab} tab.`};
        break;
    }
  }

  if (result) {
    if (result.success) {

      this.globalService.showSnackbar(result.message,"Ok");
      this.router.navigateByUrl("/exports");
    
    }else{
      console.log(result.message);
      this.message = result.message;
    }
  }
  }

  // Select Tab
  selectTab(tabName:string){
      
    if (this.currentTab != tabName) {
      this.total_page = 1;
      this.currentTab = tabName;
      this.clearFilters();
      this.clearSearch();
      if (this.isMobile) {
        this.mobileTableSortComponent.clearSort();
      }
      this.updateSort({direction:'', active:''});
      this.current_page = 1;
      this.loadInitialResult();
    }
  }

  // Get and Display Data
  async getSellInData(){
    this.isLoading = true;
    this.message = '';
    this.sellInData = [];
    
    this.requestId += 1;
    var requestId = this.requestId;

    var itemsPerPage = this.isMobile ? 5 : 15 ;
    // search by term
    if (this.searchValue) {
      switch (this.currentTab) {
        case 'tp':
          var result = await this.sellInService.searchSellInTp(itemsPerPage, (this.current_page*itemsPerPage)-itemsPerPage, this.searchValue, this.sort_by, this.sort_order);
          this.mountData(result, requestId);
          break;
        
        case 'dealer':
          var result = await this.sellInService.searchSellInDealer(itemsPerPage, (this.current_page*itemsPerPage)-itemsPerPage, this.searchValue, this.sort_by, this.sort_order);
          this.mountData(result, requestId);
          break;
        
        case 'retailer':
          var result = await this.sellInService.searchSellInRetailer(itemsPerPage, (this.current_page*itemsPerPage)-itemsPerPage, this.searchValue, this.sort_by, this.sort_order);
          this.mountData(result, requestId);
          break;

        default:
          break;
      }
    }
    // search by criteria
    else if (Object.keys(this.sellInFilter).length > 0) {
      var filterJson = this.formatFilterJson(this.sellInFilter, itemsPerPage, (this.current_page*itemsPerPage) - itemsPerPage, this.sort_by, this.sort_order);
      switch (this.currentTab) {
        case 'tp':
          var result = await this.sellInService.filterSellInTp(filterJson);
          this.mountData(result, requestId);
          
          break;
        case 'dealer':
        var result = await this.sellInService.filterSellInDealer(filterJson);
        this.mountData(result, requestId);
        
          break;
        case 'retailer':
        var result = await this.sellInService.filterSellInRetailer(filterJson);
        this.mountData(result, requestId);
        
          break;
        default:
          break;
      }
    } 
    // default fetch
    else {
      switch (this.currentTab) {
        case 'tp':
          var result = await this.sellInService.getSellInTpRecord(itemsPerPage, (this.current_page*itemsPerPage)-itemsPerPage, this.sort_by, this.sort_order);
          this.mountData(result, requestId);
          break;
        
        case 'dealer':
          var result = await this.sellInService.getSellInDealerRecord(itemsPerPage, (this.current_page*itemsPerPage)-itemsPerPage, this.sort_by, this.sort_order);
          this.mountData(result, requestId);
          break;
        
        case 'retailer':
          var result = await this.sellInService.getSellInRetailerRecord(itemsPerPage, (this.current_page*itemsPerPage)-itemsPerPage, this.sort_by, this.sort_order);
          this.mountData(result, requestId);

          break;
        default:
          break;
      }
    }
  }
  mountData(result: any, requestId: number){
    if (this.requestId == requestId) {
      if(result){
        if (result.message) {
          this.message = result.message;
        }else{
          this.sellInData = result.items;
        }
      }
      
    if (this.sellInData.length == 0 && !this.message) {
      this.message = 'No item available.'
    }
    this.isLoading = false;
    }
  }
  
  // Get and Display Data
  async getSellInDataTotal(){
    this.requestTotalId += 1;
    var requestTotalId = this.requestTotalId;

    var itemsPerPage = this.isMobile ? 5 : 15 ;
    // search by term
    if (this.searchValue) {
      switch (this.currentTab) {
        case 'tp':
          var result = await this.sellInService.searchSellInTpTotal(this.searchValue);
          this.mountDataTotal(result, itemsPerPage, requestTotalId);
          break;
        
        case 'dealer':
          var result = await this.sellInService.searchSellInDealerTotal(this.searchValue);
          this.mountDataTotal(result, itemsPerPage, requestTotalId);
          break;
        
        case 'retailer':
          var result = await this.sellInService.searchSellInRetailerTotal(this.searchValue);
          this.mountDataTotal(result, itemsPerPage, requestTotalId);
          break;

        default:
          break;
      }
    }
    // search by criteria
    else if (Object.keys(this.sellInFilter).length > 0) {
      var filterJson = this.formatFilterJson(this.sellInFilter, itemsPerPage, (this.current_page*itemsPerPage) - itemsPerPage, this.sort_by, this.sort_order);
      switch (this.currentTab) {
        case 'tp':
          var result = await this.sellInService.filterSellInTpTotal(filterJson);
          this.mountDataTotal(result, itemsPerPage, requestTotalId);
          
          break;
        case 'dealer':
        var result = await this.sellInService.filterSellInDealerTotal(filterJson);
        this.mountDataTotal(result, itemsPerPage, requestTotalId);
        
          break;
        case 'retailer':
        var result = await this.sellInService.filterSellInRetailerTotal(filterJson);
        this.mountDataTotal(result, itemsPerPage, requestTotalId);
        
          break;
        default:
          break;
      }
    } 
    // default fetch
    else {
      switch (this.currentTab) {
        case 'tp':
          var result = await this.sellInService.getSellInTpTotalRecord();
          this.mountDataTotal(result, itemsPerPage, requestTotalId);
          break;
        
        case 'dealer':
          var result = await this.sellInService.getSellInDealerTotalRecord();
          this.mountDataTotal(result, itemsPerPage, requestTotalId);
          break;
        
        case 'retailer':
          var result = await this.sellInService.getSellInRetailerTotalRecord();
          this.mountDataTotal(result, itemsPerPage, requestTotalId);

          break;
        default:
          break;
      }
    }
  }
  mountDataTotal(result: any, itemsPerPage: number, requestTotalId: number){
    if (this.requestTotalId == requestTotalId) {
      if(result){
        if (!result.message) {
          if((result.total % itemsPerPage) == 0){
            this.total_page = Math.floor(result.total / itemsPerPage );
          }else{
            this.total_page = Math.floor(result.total / itemsPerPage ) + 1;
          }
        }
      }
      this.isTotalLoading = false;
    }
  } 


  

  
}
