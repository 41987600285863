
<tr>
    <div class="sort-title d-flex">
        <span class="sort-label">
            Sort by
        </span>
    </div>
    

    <div class="sort-card">
        <button 
        *ngFor="let item of sortItems;"
        type="button" 
        (click)="sortChange(item[1])"
        [ngClass]="{'active-btn':item[1] == sort_by}"
        class="btn btn-outline-primary sort-button d-flex">
            <span>{{item[0]}}</span>
        
            <div *ngIf="sort_by == item[1]">

                <svg 
                *ngIf="sort_order == 'asc'"
                xmlns="http://www.w3.org/2000/svg" 
                width="16" 
                height="16" 
                fill="currentColor" 
                class="bi bi-arrow-up" 
                viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                </svg>
                
                <svg
                *ngIf="sort_order == 'desc'"
                xmlns="http://www.w3.org/2000/svg" 
                width="16" 
                height="16" 
                fill="currentColor" 
                class="bi bi-arrow-down" 
                viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                </svg>
            </div>

        </button>
    </div>
</tr>