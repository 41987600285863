<div ngbDropdown class="d-inline-block dropdown-div">
    <input 
    type="text" 
    class="form-control input-dropdown" 
    id="dropdownTpUsernamesFilter" 
    placeholder="Territorial Partner"
    [(ngModel)] = "tpNameFilter"
    (focusout)="onFocusOut()"
    (ngModelChange)="ngModelChange()"
    ngbDropdownToggle>
    <div ngbDropdownMenu aria-labelledby="dropdownTpUsernamesFilter" 
        class="dropdown-filter-list" >
        <button 
            ngbDropdownItem 
            *ngFor="let tpName of tpNameList;"
            (click)="updateSelectedTp(tpName.tp_username)">
            {{ tpName.tp_username }}
        </button>
    </div>
</div>
