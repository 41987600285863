<div class="card">
    <!-- title -->
    <div class="title">
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="#ef182e" width="25" height="25" viewBox="0 0 500 500"><path d="M280.32,491.29c110.06-13.68,197.29-100.91,211-211h-211Z"/><path d="M491.29,219.68c-13.68-110.06-100.91-197.29-211-211v211Z"/><path d="M6.83,250c0,124,92.86,226.37,212.85,241.29V8.71C99.69,23.63,6.83,126,6.83,250Z"/></svg>
        <h2>Sales Overview</h2>
    </div>

    <div class="body">

        <!-- top 5 sales -->
        <div class="top-5-list-div">
            <h3> Top 5 Sales</h3>
            <!-- top list -->
            <div class="top-5-list">
                <div *ngFor="let item of tp_username; let i = index" class="top-list-item chart-color">
                    <div class="tp-username">
                        <div class="circle"></div>
                        <span>{{tp_username[i]}}</span>
                    </div>
                    <span class="amount"> ₱ {{globalsService.numberWithCommas(amounts[i])}} </span>
                </div>
            </div>
    
        </div>
        
        <!-- doughnut chart -->
        <div class="chart">
            <canvas baseChart
                [data]="doughnutChartData"
                [type]="chartType">
            </canvas>
        </div>

    </div>

</div>
