import { Component, Input,  Output, EventEmitter } from '@angular/core';
import { CignalService } from 'src/app/cignal.service';

@Component({
  selector: 'app-tp-username-filter',
  templateUrl: './tp-username-filter.component.html',
  styleUrls: ['./tp-username-filter.component.css']
})
export class TpUsernameFilterComponent {
 
  tpNameFilter : string = '';
  tpNameList:Array<any> = [];
  seller_usernames_bkup:Array<any> = [];
  @Output() updateTpNameFilter = new EventEmitter<string>();

  constructor(private cignalService : CignalService){}

  ngOnInit(){
    this.getTpNames();
  }
  async getTpNames() {
    this.tpNameList = await this.cignalService.getAllTpNames();
    this.seller_usernames_bkup = this.tpNameList;
  }

  updateSelectedTp(value: string) {
    this.tpNameFilter = value;
    this.updateTpNameFilter.emit(value);
  }
  onFocusOut() {
    // this.updateTpNameFilter.emit(this.tpNameFilter);
  }
  ngModelChange() {
    this.tpNameList = this.seller_usernames_bkup;
    if(this.tpNameFilter){
      this.tpNameList = this.tpNameList.filter(
        tpName => tpName.tp_username.indexOf(this.tpNameFilter) >= 0);
    }
  }
  clearTpNameFilter(){
    this.tpNameFilter = "";
  }
}

