import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import { firstValueFrom, lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { environment } from './../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CignalService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    public globalService: GlobalService
  ) {}

  //  DASHBOARD SUMMARY RELATED APIs
  async getTotalRevenue(){
    const current_yr = (new Date()).getFullYear();
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      console.log(environment.cignalApiUrl);
      var APIEndPoint:string = `${environment.cignalApiUrl}/sales/tp/total/revenue/yearly?year=${current_yr}`;  
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );      
        if (response.status == 200) {
          if (response.body.message) {
            console.log(response.body.message); 
            return false;
          }else{ 
            return response.body.total;
          }
        }else{
          return false;
        }
      } catch (error) {
        return false; 
      }
    }else{
      return false;
    }
  }
  async getMonthlySales(){
    
    
    var current_month = (new Date()).getMonth() + 1;
    var current_yr = (new Date()).getFullYear();

    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sales/tp/total/revenue/monthly?year=${current_yr}&month=${current_month}`;  
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );      
        if (response.status == 200) {
          if (response.body.message) {
            console.log(response.body.message); 
            return false;
          }else{ 
            return response.body.total;
          }
        }else{
          return false;
        }
      } catch (error) {
        return false; 
      }
    }else{
      return false;
    }
  }
  async getWeeklySales(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sales/tp/total/revenue/weekly`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );      
        if (response.status == 200) {
          if (response.body.message) {
            console.log(response.body.message); 
            return false;
          }else{ 
            return response.body.total;
          }
        }else{
          return false;
        }
      } catch (error) {
        return false; 
      }
    }else{
      return false;
    }
  }
  async getTopTPs(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sales/tp/top5`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );      
        if (response.status == 200) {
          if (response.body.message) {
            console.log(response.body.message); 
            return false;
          }else{ 
            var amounts = [];
            var tp_username = [];
            var items = response.body.items;

            for (let index = 0; index < items.length; index++) {
              const element = items[index];

              tp_username.push(element.tp_username);
              amounts.push(element.amount);
              
            }

            return { 
              "total": response.body.total,
              "tp_username": tp_username,
              "amounts": amounts
            };
          }
        }else{
          return false;
        }
      } catch (error) {
        return false; 
      }
    }else{
      return false;
    }

  }


  // Exports SALES
  async exportSalesRecord(){
    console.log("exportSalesRecord");
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/sales`;
      var body = {
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportSalesByTerms(searchValue:string){
    console.log("exportSalesByTerms");
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/sales/by/term`;
      var body = {
        "search_term": searchValue,
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportSalesByCriteria(filterJson:any){
    console.log("exportSalesByCriteria");
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/sales/by/criteria`;
      filterJson.username = `${this.globalService.getUserInformation().username}`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  

  // Exports PURCHASE ORDER
  async exportPurchaseOrderRecord(){
    console.log("exportSalesRecord");
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/orders`;
      var body = {
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportPurchaseOrderByTerms(searchValue:string){
    console.log("exportSalesByTerms");
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/orders/by/term`;
      var body = {
        "search_term": searchValue,
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportPurchaseOrderByCriteria(filterJson:any){
    console.log("exportSalesByCriteria");
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/orders/by/criteria`;
      filterJson.username = `${this.globalService.getUserInformation().username}`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }

  // EXPORT SELL OUT 
  async exportSellOutTp(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/cignalsellouts/tp`;
      var body = {
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportSellOutDealer(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/cignalsellouts/dealer`;
      var body = {
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportSellOutRetailer(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/cignalsellouts/retailer`;
      var body = {
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }

  // EXPORT SELL OUT BY TERM
  async exportSellOutTpByTerms(searchValue:string){
    console.log("exportSalesByTerms");
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/cignalsellouts/tp/by/term`;
      var body = {
        "search_term": searchValue,
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportSellOutDealerByTerms(searchValue:string){
    console.log("exportSalesByTerms");
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/cignalsellouts/dealer/by/term`;
      var body = {
        "search_term": searchValue,
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportSellOutRetailerByTerms(searchValue:string){
    console.log("exportSalesByTerms");
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/cignalsellouts/retailer/by/term`;
      var body = {
        "search_term": searchValue,
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }

  // EXPORT SELL OUT BY CRITERIA
  async exportSellOutTpByCriteria(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/cignalsellouts/tp/by/criteria`;
      filterJson.username = `${this.globalService.getUserInformation().username}`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportSellOutDealerByCriteria(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/cignalsellouts/dealer/by/criteria`;
      filterJson.username = `${this.globalService.getUserInformation().username}`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportSellOutRetailerByCriteria(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/cignalsellouts/retailer/by/criteria`;
      filterJson.username = `${this.globalService.getUserInformation().username}`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }

  // EXPORT SELL IN 
  async exportSellInTp(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/sellins/tp`;
      var body = {
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportSellInDealer(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/sellins/dealer`;
      var body = {
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportSellInRetailer(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/sellins/retailer`;
      var body = {
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }

  // EXPORT SELL OUT BY TERM
  async exportSellInTpByTerms(searchValue:string){
    console.log("exportSalesByTerms");
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/sellins/tp/by/term`;
      var body = {
        "search_term": searchValue,
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportSellInDealerByTerms(searchValue:string){
    console.log("exportSalesByTerms");
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/sellins/dealer/by/term`;
      var body = {
        "search_term": searchValue,
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportSellInRetailerByTerms(searchValue:string){
    console.log("exportSalesByTerms");
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/sellins/retailer/by/term`;
      var body = {
        "search_term": searchValue,
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }

  // EXPORT SELL OUT BY CRITERIA
  async exportSellInTpByCriteria(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/sellins/tp/by/criteria`;
      filterJson.username = `${this.globalService.getUserInformation().username}`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportSellInDealerByCriteria(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/sellins/dealer/by/criteria`;
      filterJson.username = `${this.globalService.getUserInformation().username}`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportSellInRetailerByCriteria(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/sellins/retailer/by/criteria`;
      filterJson.username = `${this.globalService.getUserInformation().username}`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  
  // Exports PURCHASE ORDER
  async exportSellOutRecord(){
    console.log("exportSalesRecord");
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/sellouts`;
      var body = {
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportSellOutByTerms(searchValue:string){
    console.log("exportSalesByTerms");
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/sellouts/by/term`;
      var body = {
        "search_term": searchValue,
        "username": `${this.globalService.getUserInformation().username}` 
      };
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }
  async exportSellOutByCriteria(filterJson:any){
    console.log("exportSalesByCriteria");
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/sellouts/by/criteria`;
      filterJson.username = `${this.globalService.getUserInformation().username}`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        return {
          "success": true,
          "message": response.body.message
        };
      } catch (e:any) {
        return {
          "success": false,
          "message": e.error.message
        }; 
      }
    }else{
      return false;
    }
  }

  // EXPORTS PAGE
  async getExportRequestRecord(limit:number = 20, offset:number = 0){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var username = this.globalService.getUserInformation().username
      var APIEndPoint:string = `${environment.cignalApiUrl}/download/list/${username}?limit=${limit}&offset=${offset}`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );      
        if (response.status == 200) {
          if (response.body.message) {
            return false;
          }else{ 
            return {
              "items": response.body.items,
              "total": response.body.total,
            }; 
          }
        }else{
          return false;
        }
      } catch (error) {
        return false; 
      }
    }else{
      return false;
    }
  }
  async downloadExportedCSV(APIEndPoint:string, fileName:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      try {
        var res = await lastValueFrom<HttpResponse<any>>(this.http.get(APIEndPoint, {
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
            observe: 'response',
            responseType: 'blob',
          }
        ));
        this.saveFile(res.body, "text/csv", fileName);
        return true; 
      } catch (error) {

        console.log(error);
        return false; 
      }
    }else{
      return false;
    }

  }
  saveFile(data:any, type:string, filename:string) {
      let blob = new Blob([data], { type: type});
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
  }
  
// LOOK UPS API
  async getAllRoleType(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/lookup/rolename/all`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );      
        if (response.status == 200) {
          if (response.body.message) {
            console.log(response.body.message); 
            return false;
          }else{ 
            return response.body.items;
          }
        }else{
          return false;
        }
      } catch (error) {
        return false; 
      }
    }else{
      return false;
    }

  }
  async getAllTpNames(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/lookup/tp/all`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );      
        if (response.status == 200) {
          if (response.body.message) {
            console.log(response.body.message); 
            return false;
          }else{  
            return response.body.items;
          }
        }else{
          return false;
        }
      } catch (error) {
        return false; 
      }
    }else{
      return false;
    }

  }
  async getAllUserNames(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/lookup/sellerusername/all`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );      
        if (response.status == 200) {
          if (response.body.message) {
            return false;
          }else{  
            return response.body.items;
          }
        }else{
          return false;
        }
      } catch (error) {
        console.log(error); 
        return false; 
      }
    }else{
      return false;
    }

  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

}
