import { Component } from '@angular/core';
import { ChartData, ChartEvent, ChartType } from 'chart.js';
import { GlobalService } from '../global.service';
import { CignalService } from '../cignal.service';

@Component({
  selector: 'app-sales-overview',
  templateUrl: './sales-overview.component.html',
  styleUrls: ['./sales-overview.component.css']
})
export class SalesOverviewComponent {

  public chartType: ChartType = 'doughnut';
  public doughnutChartData: ChartData<'doughnut'>;
  public tp_username:Array<string> = [];
  public amounts:Array<number> = [];

  constructor(
    public  globalsService: GlobalService,
    private  cignalService: CignalService
  ){ 
    this.doughnutChartData = {
      labels: [ 'Loading...' ],
      datasets: [
        { 
          data: [],
          backgroundColor: this.globalsService.chartColors,
        }
      ]
    };

    this.getTopTPs();
  
  }

  async getTopTPs(){
    const data = await this.cignalService.getTopTPs();
    
    if (data != false) {
      this.tp_username = data.tp_username;
      this.amounts = data.amounts;
      this.doughnutChartData = {
        labels: [],
        datasets: [
          { 
            data: data.amounts,
            backgroundColor: this.globalsService.chartColors,
          }
        ]
      };
      
    }

  }

  // events
  public chartClicked({ event, active }: { event: ChartEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: ChartEvent, active: {}[] }): void {
    console.log(event, active);
  }

}
