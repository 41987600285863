import { Component } from '@angular/core';
import { GlobalService } from '../global.service';
import { CignalService } from '../cignal.service';

@Component({
  selector: 'app-export-page',
  templateUrl: './export-page.component.html',
  styleUrls: ['./export-page.component.css']
})
export class ExportPageComponent {
  exportRequestRecords:Array<any> = [];
  total_page:number = 0;
  current_page:number = 1;
  isLoading:boolean = false;
  temp_searchValue:string = "";
  searchValue:string = "";
  
  constructor(
    private cignalService: CignalService,
    public globalService: GlobalService
  ){}
  
  ngOnInit(){
    this.getExportRequestRecord();
  }

  downloadExportedCSV(location:string, fileName:string){
    this.cignalService.downloadExportedCSV(location, fileName);
  }

  async getExportRequestRecord(){
    this.isLoading = true;
    var offset = (this.current_page * 10) - 10; 
    var result = await this.cignalService.getExportRequestRecord(10, offset);
    if (result) {
      this.exportRequestRecords = result.items;
      this.total_page = Math.ceil(result.total[0].total / 10 );

    }
    this.isLoading = false;
  }

  updateOrderHistoryRecordPage(pageNo:number){
    this.current_page = pageNo;
    this.getExportRequestRecord();
  }

  searchOrder(){

  }

  clearsearchOrder(){

  }

}
