import { Component, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-filter-search-bar',
  templateUrl: './filter-search-bar.component.html',
  styleUrls: ['./filter-search-bar.component.css']
})
export class FilterSearchBarComponent {
  temp_searchValue = "";
  @Input() searchValue = "";
  @Output() updateSearchValue = new EventEmitter<string>();

  searchOrder(){
    if (this.temp_searchValue != this.searchValue) {
      this.searchValue = this.temp_searchValue;
      this.updateSearchValue.emit(this.searchValue);
    }
  }

  clearSearch(){
    this.temp_searchValue = "";
  }

}
