
<div class="date-picker-conteinare">
    <!-- from filter -->
    <form>
        <div class="col-12">
            <div class="input-group form-control date-picker">
                <input
                    class="form-control"
                    name="fromDateFilter"
                    placeholder="YYYY-MM-DD"
                    [(ngModel)]="fromDateFilter"
                    [maxDate]="maxDateFilter"
                    (dateSelect)="updateAvailableDateRange()"
                    (change)="updateAvailableDateRange()"
                    ngbDatepicker
                    #from="ngbDatepicker"
                />
                <span>From</span>
                <svg (click)="from.toggle()" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 500 500"><path d="M436.68,62.9H397.52V25.1a15,15,0,0,0-15-15H351.25a15,15,0,0,0-15,15V62.9H163.75V25.1a15,15,0,0,0-15-15H117.48a15,15,0,0,0-15,15V62.9H63.32a50,50,0,0,0-50,50v327a50,50,0,0,0,50,50H436.68a50,50,0,0,0,50-50v-327A50,50,0,0,0,436.68,62.9ZM443.16,425a25,25,0,0,1-25,25H81.84a25,25,0,0,1-25-25V156.85H443.16Z"/><rect x="102.48" y="215.12" width="61.28" height="61.28" rx="15"/><rect x="336.25" y="215.12" width="61.28" height="61.28" rx="15"/><rect x="219.36" y="215.12" width="61.28" height="61.28" rx="15"/><rect x="102.48" y="322.35" width="61.28" height="61.28" rx="15"/><rect x="336.25" y="322.35" width="61.28" height="61.28" rx="15"/><rect x="219.36" y="322.35" width="61.28" height="61.28" rx="15"/></svg>
            </div>
        </div>
    </form>
    
    <!-- to filter -->
    <form>
        <div class="col-12">
            <div class="input-group form-control date-picker">
                <input
                    class="form-control"
                    name="toDateFilter"
                    placeholder="YYYY-MM-DD"
                    [(ngModel)]="toDateFilter"
                    [minDate]="minDateFilter"
                    (dateSelect)="updateAvailableDateRange()"
                    (change)="updateAvailableDateRange()"
                    ngbDatepicker
                    #to="ngbDatepicker"
                />
                <span>To</span>
                <svg (click)="to.toggle()" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 500 500"><path d="M436.68,62.9H397.52V25.1a15,15,0,0,0-15-15H351.25a15,15,0,0,0-15,15V62.9H163.75V25.1a15,15,0,0,0-15-15H117.48a15,15,0,0,0-15,15V62.9H63.32a50,50,0,0,0-50,50v327a50,50,0,0,0,50,50H436.68a50,50,0,0,0,50-50v-327A50,50,0,0,0,436.68,62.9ZM443.16,425a25,25,0,0,1-25,25H81.84a25,25,0,0,1-25-25V156.85H443.16Z"/><rect x="102.48" y="215.12" width="61.28" height="61.28" rx="15"/><rect x="336.25" y="215.12" width="61.28" height="61.28" rx="15"/><rect x="219.36" y="215.12" width="61.28" height="61.28" rx="15"/><rect x="102.48" y="322.35" width="61.28" height="61.28" rx="15"/><rect x="336.25" y="322.35" width="61.28" height="61.28" rx="15"/><rect x="219.36" y="322.35" width="61.28" height="61.28" rx="15"/></svg>
            
            </div>
        </div>
    </form>
</div>
