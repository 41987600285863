import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './login-page/login-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PurchaseOrderHistoryComponent } from './purchase-order-history/purchase-order-history.component';
import { ExportPageComponent } from './export-page/export-page.component';
import { SellOutHistoryComponent } from './sell-out-history/sell-out-history.component';
import { SellInComponent } from './sell-in/sell-in.component';
import { SellOutComponent } from './sell-out/sell-out.component';

const routes: Routes = [
  {path:"login", component: LoginPageComponent, pathMatch: 'full'},
  {path:"dashboard", component: DashboardComponent},
  {path:"order-history", component: PurchaseOrderHistoryComponent},
  {path:"exports", component: ExportPageComponent},
  {path:"sell-history", component: SellOutHistoryComponent},
  {path:"sell-in", component: SellInComponent},
  {path:"sell-out", component: SellOutComponent},
  {path: "**", redirectTo:"/login"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
