import { Injectable } from '@angular/core';
import { AuthService } from './../../auth.service';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { GlobalService } from './../../global.service';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SellInService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    public globalService: GlobalService
  ) {}

  
//  Sell-In TP
  // REGULAR REQUEST 
  async getSellInTpRecord(limit:number = 20, offset:number = 0, sort_by:string, sort_order:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    sort_by = (sort_by == 'code') ? 'serial_number' : sort_by;
    var params = `limit=${limit}&offset=${offset}`;
    if (sort_by && sort_order) {
      params += `&sort_by=${sort_by}&sort_order=${sort_order}`
    }

    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sellins/tp?${params}`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        
        return {
          "items": response.body.items,
          "message": "",
        }; 

      } catch (e:any) {
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }
  async getSellInTpTotalRecord(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();

    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sellins/tp/total`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        
        return {
          "total": response.body.total,
          "message": "",
        }; 

      } catch (e:any) {
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }

  // SEARCH REQUEST
  async searchSellInTp(limit:number = 20, offset:number = 0, searchValue:string, sort_by:string, sort_order:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/sellins/tp/by/term`;

      var body:any = {};
      body.search_term = searchValue;
      body.limit = limit;
      body.offset = offset;

      if (sort_by && sort_order) {
        body.sort_by = sort_by,
        body.sort_order = sort_order
      }
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );  
        return {
          "items": response.body.items,
          "message": "",
        }; 
      }catch (e:any) {
        console.log(e.error.message);
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }

    }else{
      return false;
    }
  }
  async searchSellInTpTotal( searchValue:string ){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/sellins/tp/by/term/total`;

      var body:any = {};
      body.search_term = searchValue;

      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );  
        return {
          "total": response.body.total,
          "message": "",
        }; 
      }catch (e:any) {
        console.log(e.error.message);
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }

    }else{
      return false;
    }
  }

  // FILTERED REQUEST
  async filterSellInTp(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/sellins/tp/by/criteria`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
            this.http.post(APIEndPoint, body, {
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                },
                observe: 'response',
              }
            )
        );    
  
        return {
          "items": response.body.items,
          "message": "",
        }; 
        
      }catch (e:any) {
        console.log(e.error.message);
        if(e.error.message.includes('A date range is required.')){
          e.error.message = "Date range is required.";
        }
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }
  async filterSellInTpTotal(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/sellins/tp/by/criteria/total`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
            this.http.post(APIEndPoint, body, {
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                },
                observe: 'response',
              }
            )
        );    
  
        return {
          "total": response.body.total,
          "message": "",
        }; 
        
      }catch (e:any) {
        console.log(e.error.message);
        if(e.error.message.includes('A date range is required.')){
          e.error.message = "Date range is required.";
        }
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }
  

//  Sell-In DEALER
  // REGULAR REQUEST
  async getSellInDealerRecord(limit:number = 20, offset:number = 0, sort_by:string, sort_order:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    sort_by = (sort_by == 'code') ? 'serial_number' : sort_by;
    if (isTokenValid) {
      var params = `limit=${limit}&offset=${offset}`;
      if (sort_by && sort_order) {
        params += `&sort_by=${sort_by}&sort_order=${sort_order}`
      }
      var APIEndPoint:string = `${environment.cignalApiUrl}/sellins/dealer?${params}`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        
        return {
          "items": response.body.items,
          "message": "",
        }; 

      } catch (e:any) {
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }
  async getSellInDealerTotalRecord(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sellins/dealer/total`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        
        return {
          "total": response.body.total,
          "message": "",
        }; 

      } catch (e:any) {
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }

  // SEARCH REQUEST
  async searchSellInDealer(limit:number = 20, offset:number = 0, searchValue:string, sort_by:string, sort_order:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/sellins/dealer/by/term`;

      var body:any = {};
      body.search_term = searchValue;
      body.limit = limit;
      body.offset = offset;

      if (sort_by && sort_order) {
        body.sort_by = sort_by,
        body.sort_order = sort_order
      }
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );  
        return {
          "items": response.body.items,
          "message": "",
        }; 
      }catch (e:any) {
        console.log(e.error.message);
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }

    

    }else{
      return false;
    }
  }
  async searchSellInDealerTotal( searchValue:string ){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/sellins/dealer/by/term/total`;

      var body:any = {};
      body.search_term = searchValue;

      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );  
        return {
          "total": response.body.total,
          "message": "",
        }; 
      }catch (e:any) {
        console.log(e.error.message);
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }

    

    }else{
      return false;
    }
  }

  // FILTERED REQUEST
  async filterSellInDealer(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/sellins/dealer/by/criteria`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
            this.http.post(APIEndPoint, body, {
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                },
                observe: 'response',
              }
            )
        );    
  
        return {
          "items": response.body.items,
          "message": "",
        }; 
        
      }catch (e:any) {
        console.log(e.error.message);
        if(e.error.message.includes('A date range is required.')){
          e.error.message = "Date range is required.";
        }
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }
  async filterSellInDealerTotal(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/sellins/dealer/by/criteria/total`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
            this.http.post(APIEndPoint, body, {
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                },
                observe: 'response',
              }
            )
        );    
  
        return {
          "total": response.body.total,
          "message": "",
        }; 
        
      }catch (e:any) {
        console.log(e.error.message);
        if(e.error.message.includes('A date range is required.')){
          e.error.message = "Date range is required.";
        }
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }


//  Sell-In RETAILER
  // REGULAR REQUEST
  async getSellInRetailerRecord(limit:number = 20, offset:number = 0, sort_by:string, sort_order:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var params = `limit=${limit}&offset=${offset}`;
      if (sort_by && sort_order) { params += `&sort_by=${sort_by}&sort_order=${sort_order}`}
      var APIEndPoint:string = `${environment.cignalApiUrl}/sellins/retailer?${params}`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        
        return {
          "items": response.body.items,
          "message": "",
        }; 

      } catch (e:any) {
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }
  async getSellInRetailerTotalRecord(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sellins/retailer/total`;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.get(APIEndPoint, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        ); 
        
        return {
          "total": response.body.total,
          "message": "",
        }; 

      } catch (e:any) {
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }

  // SEARCH REQUEST
  async searchSellInRetailer(limit:number = 20, offset:number = 0, searchValue:string, sort_by:string, sort_order:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/sellins/retailer/by/term`;

      var body:any = {};
      body.search_term = searchValue;
      body.limit = limit;
      body.offset = offset;

      if (sort_by && sort_order) {
        body.sort_by = sort_by,
        body.sort_order = sort_order
      }
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );  
        return {
          "items": response.body.items,
          "message": "",
        }; 
      }catch (e:any) {
        console.log(e.error.message);
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }

    

    }else{
      return false;
    }
  }
  async searchSellInRetailerTotal(searchValue:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/sellins/retailer/by/term/total`;

      var body:any = {};
      body.search_term = searchValue;

      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );  
        return {
          "total": response.body.total,
          "message": "",
        }; 
      }catch (e:any) {
        console.log(e.error.message);
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }

    }else{
      return false;
    }
  }

  // FILTERED REQUEST
  async filterSellInRetailer(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/sellins/retailer/by/criteria`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
            this.http.post(APIEndPoint, body, {
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                },
                observe: 'response',
              }
            )
        );    
  
        return {
          "items": response.body.items,
          "message": "",
        }; 
        
      }catch (e:any) {
        console.log(e.error.message);
        if(e.error.message.includes('A date range is required.')){
          e.error.message = "Date range is required.";
        }
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }
  async filterSellInRetailerTotal(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/search/sellins/retailer/by/criteria/total`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
            this.http.post(APIEndPoint, body, {
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                },
                observe: 'response',
              }
            )
        );    
  
        return {
          "total": response.body.total,
          "message": "",
        }; 
        
      }catch (e:any) {
        console.log(e.error.message);
        if(e.error.message.includes('A date range is required.')){
          e.error.message = "Date range is required.";
        }
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }


}

