import { Component, Input} from '@angular/core';
import { HttpClient } from '@angular/common/http'; 

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.css']
})
export class SvgComponent {
  
  @Input() src = ''; // decorate the property with @Input()
  @Input() width = '50px'; // decorate the property with @Input()
  @Input() height = '50px'; // decorate the property with @Input()
  @Input() color = 'black'; // decorate the property with @Input()

  constructor(private http:HttpClient){}

  svgFileName = ``;

  async ngAfterViewInit(){
    var textSVG = await this.getSVGtext(this.src);
    this.svgFileName = textSVG;
  }

  getSVGtext(url : string) {
    return fetch(url)
              // Get SVG response as text
              .then(response => {
                return response.text();
              })
  }
}
