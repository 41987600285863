import { Component } from '@angular/core';
import { GlobalService } from '../global.service';
import { CignalService } from '../cignal.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import {Sort} from '@angular/material/sort';
import { SalesTransactionsService } from '../services/sales-transactions/sales-transactions.service';


@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.css']
})
export class TransactionListComponent {
    salesRecords:Array<any> = [];
    total_page:number = 0;
    current_page:number = 1;
    isLoading:boolean = false;
    requestId:number = 0;
    isTotalLoading:boolean = false;
    requestTotalId:number = 0;
    
    
    // search variables
    temp_searchValue:string = "";
    searchValue:string = "";
    
    // FILTER VARIABLES
    filterJson:any = {};
    message:string = "";
    // DATE RANGE FILTER
    minDateFilter: NgbDateStruct = {year:  2000 , month: 1, day: 1}; 
    maxDateFilter: NgbDateStruct = {year:  2100 , month: 12, day: 31}; 
    fromDateFilter: any = ""; 
    toDateFilter: any = ""; 
    // TP FILTER
    tp_usernames:Array<any> = [];
    tp_usernames_bkup:Array<any> = [];
    tpNameFilter: string = "";
    // ROLE NAME FILTER
    role_names:Array<any> = [];
    role_names_bkup:Array<any> = [];
    sellerRoleNameFilter: string = "";
    // SELLER USERNAME FILTER
    seller_usernames:Array<any> = [];
    seller_usernames_bkup:Array<any> = [];
    sellerUsernamesFilter: string = "";
    // STATUS
    statusFilter: string = "";

    constructor(
      private cignalService: CignalService,
      public globalService: GlobalService,
      private router: Router,
      private salesTransactionsService: SalesTransactionsService
    ){}

    
    ngOnInit(){
      this.getAllRoleType();
      this.getAllTpNames();
      this.getAllSellerUsername();
      this.initializeData();
    }

    async initializeData(){
      this.isTotalLoading = true;
      await this.getSalesRecord();
      await this.getSalesTotalRecord();
    }

    // TP FILTER
    async getAllTpNames(){
      var result = await this.cignalService.getAllTpNames();
      if (result) {
        if (result instanceof Array) {
          this.tp_usernames = result;
          this.tp_usernames_bkup = result;
        }
      }
    }
    setTpNameFilter(tpName:string){
        this.tpNameFilter = tpName;
        this.setSalesRecordFilter();
    }
    tpNameFilterChange(){
        this.tp_usernames = this.tp_usernames_bkup;
        if(this.tpNameFilter){
          this.tp_usernames = this.tp_usernames.filter(
            tp_username => tp_username.tp_username.indexOf(this.tpNameFilter) >= 0);
        }
    }


    // SELLER USERNAME FILTER
    async getAllSellerUsername(){
      var result = await this.cignalService.getAllUserNames();
      if (result) {
        if (result instanceof Array) {
          this.seller_usernames = result;
          this.seller_usernames_bkup = result;
        }
      }
    }
    setSellerUsernameFilter(sellerUsername:string){
      this.sellerUsernamesFilter = sellerUsername;
      this.setSalesRecordFilter();
    }
    sellerUsernameFilterChange(){
        this.seller_usernames = this.seller_usernames_bkup;
        if(this.sellerUsernamesFilter){
          this.seller_usernames = this.seller_usernames.filter(
            seller_username => seller_username.username.indexOf(this.sellerUsernamesFilter) >= 0);
        }
    }

    
    // SELLER ROLE FILTER
    async getAllRoleType(){
      var result = await this.cignalService.getAllRoleType();
      if (result) {
        if (result instanceof Array) {
          this.role_names = result;
          this.role_names_bkup = result;
        }
      }
    }
    setsellerRoleNameFilter(sellerRolseName:string){
      this.sellerRoleNameFilter = sellerRolseName;
      this.setSalesRecordFilter();
    }
    roleNameFilterChange(){
        this.role_names = this.role_names_bkup;
        if(this.sellerRoleNameFilter){
          this.role_names = this.role_names.filter(
            role_name => role_name.rolename.indexOf(this.sellerRoleNameFilter) >= 0);
        }
    }

    // FILTER FUNCTIONS    
    setSalesRecordFilter(){

      var update_flg = false;

      // UPDATE FROM DATE FILTER
      if (this.fromDateFilter) {
        var new_from_date = `${this.fromDateFilter.year}-${this.fromDateFilter.month}-${this.fromDateFilter.day}`;
        if (this.filterJson.from_date != new_from_date) {
          this.filterJson.from_date = new_from_date;  
          update_flg = true;
        }
      }else{
        if (this.filterJson.from_date) {
          delete this.filterJson.from_date; 
          update_flg = true;
        }
      }

      // UPDATE TO DATE FILTER
      if (this.toDateFilter) { 
        var new_to_date = this.globalService.addOneDayToEndDate(this.toDateFilter);
        if (this.filterJson.to_date != new_to_date) {
          this.filterJson.to_date = new_to_date;
          update_flg = true;
        } 
      }else{
        if (this.filterJson.to_date) {
          delete this.filterJson.to_date;
          update_flg = true;
        }
      }

      //  UPDATE TP NAME FILTER
      if (this.tpNameFilter) { 
        if (this.filterJson.tp_username != this.tpNameFilter) {
          this.filterJson.tp_username = this.tpNameFilter;
          update_flg = true;
        }
      }else{
        if (this.filterJson.tp_username) {
          delete this.filterJson.tp_username; 
          update_flg = true;
        }
      }
      
      // UPDATE SATATUS FILTER
      if (this.statusFilter) { 
        if (this.filterJson.status != this.statusFilter) {
          this.filterJson.status = this.statusFilter;  
          update_flg = true;
        }
      }else{
        if (this.filterJson.status) {
          delete this.filterJson.status;
          update_flg = true;
        }
      }
      
      // UPDATE SELLER ROLE NAME FILTER
      if (this.sellerRoleNameFilter) { 
        if (this.filterJson.seller_rolename != this.sellerRoleNameFilter) {
          this.filterJson.seller_rolename = this.sellerRoleNameFilter;
          update_flg = true;
        } 
      }else{
        if (this.filterJson.seller_rolename) {
          delete this.filterJson.seller_rolename;
          update_flg = true;
        }
      }
      
      // UPDATE SELLER USERNAME FILTER
      if (this.sellerUsernamesFilter) { 
        if (this.filterJson.seller_username != this.sellerUsernamesFilter) {
          this.filterJson.seller_username = this.sellerUsernamesFilter;
          update_flg = true;
        }
      }else{
        if (this.filterJson.seller_username) {
          delete this.filterJson.seller_username;
          update_flg = true;
        }
      }

      if (update_flg) {
        
        this.current_page = 1;
        this.searchValue = "";
        this.temp_searchValue = "";
        this.initializeData(); 
      }

    }
    setStatusFilter(statusFilter:string){
      this.statusFilter = statusFilter;
      this.setSalesRecordFilter();
    }
    updateAvailableDateRange(){
      // for fromDateFilter
      if (typeof(this.fromDateFilter ) == "string") {
        // convert string to NgbDateStruct if date was typed
         // return empty string if entered text is an invalid date format
        this.fromDateFilter = this.globalService.formatStringToNgbDateStruct(this.fromDateFilter);
      }
      
      if (typeof(this.fromDateFilter ) == "object") {
        this.minDateFilter = this.fromDateFilter;
        this.setSalesRecordFilter();
      }else if (typeof(this.fromDateFilter ) == "string") {
        this.minDateFilter = {year:  1900 , month: 1, day: 1};
      }

      // for toDateFilter
      if (typeof(this.toDateFilter ) == "string") {
        // convert string to NgbDateStruct if date was typed
        // return empty string if entered text is an invalid date format
        this.toDateFilter = this.globalService.formatStringToNgbDateStruct(this.toDateFilter);
      }

      if (typeof(this.toDateFilter ) == "object") {
        this.maxDateFilter = this.toDateFilter;
        this.setSalesRecordFilter();
      }else if (typeof(this.toDateFilter ) == "string") {
        this.maxDateFilter = {year:  2100 , month: 12, day: 31};
      }



    }
    showFilter(val:boolean){
      let sidebar = document.getElementById("filter_div") as HTMLElement;
      if (sidebar) {
        var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        if (width < 600) {
          if (val) {
            sidebar.style.display = "flex";
          }else{
            sidebar.style.display = "none";
          } 
        }
      }
    }

    // SORT FUNCTION
    sortData(sort:Sort){
      delete  this.filterJson.sort_by;
      delete  this.filterJson.sort_order;
      if (sort.direction) {
        this.filterJson.sort_by = sort.active;
        this.filterJson.sort_order = sort.direction;
      }
      this.getSalesRecord();
    }


    // EXPORT
    async exportSalesRecord(){
      var offset = (this.current_page * 10) - 10; 
      this.message = "";
 
      // get sales record by search
      if (this.searchValue) {
       var result = await this.cignalService.exportSalesByTerms(this.searchValue);
      }
      // get sales record by filter
      else if(Object.keys(this.filterJson).length > 0)
      {
       var result = await this.cignalService.exportSalesByCriteria(this.filterJson);
      }
      // get all sales record
      else{
        var result = await this.cignalService.exportSalesRecord();
      }
 
      if (result) {
        if (result.success) {

          this.globalService.showSnackbar(result.message,"Ok");
          this.router.navigateByUrl("/exports");
        
        }else{
          this.message = result.message;
        }
      }
    }
    // PAGINATION
    updateSaleRecordPage(pageNo:number){
      this.current_page = pageNo;
      this.getSalesRecord();
    }
    // SEARCH FUNCTIONS
    searchSale(){
      if (this.searchValue.trim() != this.temp_searchValue.trim()) {
         this.searchValue = this.temp_searchValue.trim();
         this.current_page = 1;
        //  reset filter value
         this.minDateFilter = {year:  2000 , month: 1, day: 1}; 
         this.maxDateFilter = {year:  2100 , month: 12, day: 31}; 
         this.fromDateFilter = ""; 
         this.toDateFilter = ""; 
         this.tpNameFilter = "";
         this.sellerRoleNameFilter = "";
         this.sellerUsernamesFilter = "";
         this.statusFilter = "";
         this.filterJson = {};
      }
      
      this.showFilter(false);
      this.initializeData();
    }
    clearSearchSale(){
      this.searchValue = "";
      this.temp_searchValue = "";
      this.current_page = 1;
      //  reset filter value
      this.minDateFilter = {year:  2000 , month: 1, day: 1}; 
      this.maxDateFilter = {year:  2100 , month: 12, day: 31}; 
      this.fromDateFilter = ""; 
      this.toDateFilter = ""; 
      this.tpNameFilter = "";
      this.sellerRoleNameFilter = "";
      this.sellerUsernamesFilter = "";
      this.statusFilter = "";
      this.filterJson = {};
      this.showFilter(false);
      this.initializeData();

    }

    async getSalesRecord(){
      this.message = "";
      this.salesRecords = [];
      this.requestId += 1;
      var requestId = this.requestId; 
      var itemsPerPage = 15;
      var offset = (this.current_page * itemsPerPage) - itemsPerPage;
      delete this.filterJson.limit;
      delete this.filterJson.offset;
      this.isLoading = true;
      // get sales record by search
      if (this.searchValue) {
       var sort_by = this.filterJson.sort_by ?? "";
       var sort_order = this.filterJson.sort_order ?? "";
 
       var result = await this.salesTransactionsService.searchSales(itemsPerPage, offset, this.searchValue, sort_by, sort_order);
      }
      // get sales record by filter
      else if(Object.keys(this.filterJson).length > 0)
      {
       this.filterJson.limit = itemsPerPage;
       this.filterJson.offset = (this.current_page * itemsPerPage) - itemsPerPage;
     
       var result = await this.salesTransactionsService.filterSales(this.filterJson);
      }
      // get all sales record
      else{
        var result = await this.salesTransactionsService.getSalesRecord(itemsPerPage, offset);
      }
 
      this.mountData(result, requestId);
 
    }
    mountData(result: any, requestId: number){
      if (requestId == this.requestId) {
        if (result) {
          if (result.message) {
            this.message = result.message;
          }else{
            this.salesRecords = result.items;
          }
         }
  
        if (this.salesRecords.length == 0 && !this.message) {
          this.message = 'No item available.'
        }
        this.isLoading = false;
        
      }
    }

    async getSalesTotalRecord(){
      this.requestTotalId += 1;
      var itemsPerPage = 15;
      var requestTotalId = this.requestTotalId;

      // get sales record by search
      if (this.searchValue) 
      {
       var result = await this.salesTransactionsService.searchSalesTotal(this.searchValue);
      }
      // get sales record by filter
      else if(Object.keys(this.filterJson).length > 0)
      {
       var result = await this.salesTransactionsService.filterSalesTotal(this.filterJson);
      }
      // get all sales record
      else{
        var result = await this.salesTransactionsService.getSalesTotalRecord();
      }
 
      this.mountTotalData(result, itemsPerPage, requestTotalId);
 
    }

    mountTotalData(result: any, itemsPerPage: number, requestTotalId: number){
      console.log(">>>>>>");
      console.log(result);
      console.log(itemsPerPage);

      if (requestTotalId == this.requestTotalId) {
        if (result) {
          if (!result.message) {
            if((result.total % itemsPerPage) == 0){
              this.total_page = Math.floor(result.total / itemsPerPage );  
            }else{
              this.total_page = Math.floor(result.total / itemsPerPage ) + 1;
            }
          }
         }
        this.isTotalLoading = false;
      }
    }

}
