import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent {

  @Output() onUpdatePage = new EventEmitter<number>();  
  @Input() current_page : number = 1;  
  @Input() isLoading : boolean = false;  
  @Input() total_page : number = 1;  

  updatePage(newPage:number){
    this.onUpdatePage.emit(newPage)
  }

}
