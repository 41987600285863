<div class="notif-and-account">

    <button class="top-bar-btn menu-button" (click)="toggleSideBar()">
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 500 500"><path d="M34.38,104H465.62a25,25,0,1,0,0-50H34.38a25,25,0,0,0,0,50Z"/><path d="M465.62,225H34.38a25,25,0,0,0,0,50H465.62a25,25,0,0,0,0-50Z"/><path d="M465.62,396H34.38a25,25,0,0,0,0,50H465.62a25,25,0,0,0,0-50Z"/></svg>
    </button>

    <div class="cignal-img">
        <img src="assets/images/cignal_logo.png">
    </div>

    <!-- <button class="top-bar-btn btn-notif">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-bell-fill" viewBox="0 0 16 16">
            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
        </svg>
    </button> -->

    <div class="top-bar-btn btn-notif">
        <span class="acc-name">        
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 500 500"><defs><style>.cls-1{fill:none;}</style></defs><path class="cls-1" d="M374.22,397.81C365,337.54,312.81,291.22,250,291.22S135,337.54,125.78,397.81a192.77,192.77,0,0,0,248.44,0Z"/><circle class="cls-1" cx="250" cy="199.07" r="42.15" transform="translate(-29.59 353.86) rotate(-67.5)"/><path class="cls-1" d="M178.09,256.63a92.15,92.15,0,1,1,143.82,0,176.65,176.65,0,0,1,91.62,96.09A192.11,192.11,0,0,0,443.17,250c0-106.52-86.65-193.17-193.17-193.17S56.83,143.48,56.83,250A192,192,0,0,0,86.47,352.72,176.65,176.65,0,0,1,178.09,256.63Z"/><path d="M422,78.05A243.17,243.17,0,0,0,74.35,418.16c1.22,1.27,2.45,2.54,3.7,3.79A242.78,242.78,0,0,0,422,422c1.25-1.25,2.48-2.52,3.7-3.79A243.17,243.17,0,0,0,422,78.05ZM250,443.17a192.31,192.31,0,0,1-124.22-45.36C135,337.54,187.19,291.22,250,291.22s115,46.32,124.22,106.59A192.31,192.31,0,0,1,250,443.17Zm-42.15-244.1A42.15,42.15,0,1,1,250,241.22,42.2,42.2,0,0,1,207.85,199.07ZM413.53,352.72a176.65,176.65,0,0,0-91.62-96.09,92.15,92.15,0,1,0-143.82,0,176.65,176.65,0,0,0-91.62,96.09A192,192,0,0,1,56.83,250C56.83,143.48,143.48,56.83,250,56.83S443.17,143.48,443.17,250A192.11,192.11,0,0,1,413.53,352.72Z"/></svg>
            <span>{{userDetails.firstname}}</span>
        </span>
    </div>
    
    <button class="top-bar-btn">  
        <svg (click)="logout()" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 500 500"><path d="M485.24,239.44,462.82,217l-60.24-60.24a25,25,0,1,0-35.35,35.35l32.2,32.21H123.89a25,25,0,0,0,0,50H399.43l-32.2,32.21a25,25,0,1,0,35.35,35.36l60.24-60.25,22.42-22.41a24.88,24.88,0,0,0,6.08-9.91A24.78,24.78,0,0,0,485.24,239.44Z"/><path d="M292.9,414.26a27.29,27.29,0,0,1-27.26,27.25H85.93a27.29,27.29,0,0,1-27.25-27.25V85.74A27.29,27.29,0,0,1,85.93,58.49H265.64A27.29,27.29,0,0,1,292.9,85.74V113h50V85.74A77.35,77.35,0,0,0,265.64,8.49H85.93A77.34,77.34,0,0,0,8.68,85.74V414.26a77.34,77.34,0,0,0,77.25,77.25H265.64a77.35,77.35,0,0,0,77.26-77.25V385.68h-50Z"/></svg>
    </button>
  
</div>