
        <!-- data table -->
        <div 
        *ngIf="!isMobile"
        matSort 
        (matSortChange)="sortData($event)"
        class="table-div">
            <table class="table table-striped">
                <thead>
                    <tr>
                        
                        <th 
                        scope="col"
                        mat-sort-header="retailer_username">
                        Retailer
                        </th>

                        <th 
                        scope="col"
                        mat-sort-header="dealer_username">
                        Dealer
                        </th>

                        <th 
                        scope="col"
                        mat-sort-header="tp_username">
                        Territorial Partner
                        </th>

                        <th 
                        scope="col"
                        mat-sort-header="product_name">
                            e-Pins Denoms
                        </th>
                        
                        <th 
                        scope="col"
                        mat-sort-header="amount">
                            Amount
                        </th>
                        
                        <th 
                        scope="col"
                        mat-sort-header="create_date">
                            Date
                        </th>

                        <th 
                        scope="col"
                        mat-sort-header="subscriber">
                            Subscriber
                        </th>
                        
                    </tr>
                </thead>
                <tbody *ngIf="!isLoading && !message">
                    <tr *ngFor="let sellOut of sellOutData; index as i">
                        <td>{{ sellOut.retailer_username }}</td>
                        <td>{{ sellOut.dealer_username ? sellOut.dealer_username : 'N/A' }}</td>
                        <td>{{ sellOut.tp_username }}</td>
                        <td>{{ sellOut.product_name }}</td>
                        <td>₱{{ globalservice.numberWithCommas(sellOut.amount) }}</td>
                        <td>{{ sellOut.create_date }}</td>
                        <td>{{ sellOut.subscriber }}</td>
                    </tr>
                </tbody>

                
                <div 
                *ngIf="message && !isLoading" 
                class="m-auto error-message">
                    <span>{{message}}</span>
                </div>

                <div 
                *ngIf="isLoading" class="table-spinner-div">
                    <span class="sr-only"> <div class="spinner-border" role="status"> </div> Loading...</span>
                </div>

            </table>
        </div>

        <div 
        *ngIf="isMobile"
        class="card-list">
            
            <div *ngIf="!isLoading && !message">
                <tr *ngFor="let sellOut of sellOutData; index as i">
                    <div class="items-card">
                        <span><span class="label">Retailer: </span>{{ sellOut.retailer_username }}</span>
                        <span><span class="label">Dealer: </span>{{ sellOut.dealer_username ? sellOut.dealer_username : 'N/A' }}</span>
                        <span><span class="label">Territorial Partner: </span>{{ sellOut.tp_username }}</span>
                        <span><span class="label">e-Pins Denoms: </span>{{ sellOut.product_name }}</span>
                        <span><span class="label">Amount: </span>₱{{ globalservice.numberWithCommas(sellOut.amount) }}</span>
                        <span><span class="label">Date: </span>{{ sellOut.create_date }}</span>
                        <span><span class="label">Subscriber: </span>{{ sellOut.subscriber }}</span>
                    </div>
                </tr>
            </div>
            
            <div *ngIf="message && !isLoading" class="m-auto error-message"><span>{{message}}</span></div>

            <div *ngIf="isLoading" class="table-spinner-div">
                <span class="sr-only"> <div class="spinner-border" role="status"> </div> Loading...</span>
            </div>

        </div>