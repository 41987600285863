<div class="side-bar-bg" id="side_bar">
    <div class="side-bar">
        <img class="cignal-img" src="assets/images/cignal_logo.png">
    
        <div class="side-bar-item-list">
            <a href="/dashboard"  [ngClass]="{'active': page == 1}" class="side-bar-item">
                <app-svg class="side-svg" 
                    width="20px"
                    height="20px"
                    color="{page == 1 ? 'black' : 'white'}"
                    src="assets/svg/dashboard.svg">
                </app-svg>
                <span>        
                    Dashboard
                </span>
            </a>
            <!-- <a href="" class="side-bar-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
                    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
                </svg>
                <span>        
                    User List
                </span>
            </a> -->
            <a href="order-history" [ngClass]="{'active': page == 3}" class="side-bar-item">
                <app-svg class="side-svg" 
                    width="20px"
                    height="20px"
                    color="{page == 1 ? 'black' : 'white'}"
                    src="assets/svg/purchase_order_history.svg">
                </app-svg>
                <span>        
                    Purchase Order History
                </span>
            </a>
            <a href="sell-history" [ngClass]="{'active': page == 4}" class="side-bar-item">
                <app-svg class="side-svg" 
                    width="20px"
                    height="20px"
                    color="{page == 1 ? 'black' : 'white'}"
                    src="assets/svg/sell_out_history.svg">
                </app-svg>
                <span>        
                    Sell-out History
                </span>
            </a>
            <a href="sell-out" [ngClass]="{'active': page == 6}" class="side-bar-item">
                <app-svg class="side-svg" 
                    width="20px"
                    height="20px"
                    color="{page == 1 ? 'black' : 'white'}"
                    src="assets/svg/sell-out.svg">
                </app-svg>
                <span>        
                    Sell-out
                </span>
            </a>
            <a href="sell-in" [ngClass]="{'active': page == 7}" class="side-bar-item">
                <app-svg class="side-svg" 
                    width="20px"
                    height="20px"
                    color="{page == 1 ? 'black' : 'white'}"
                    src="assets/svg/sell-in.svg">
                </app-svg>
                <span>        
                    Sell-in
                </span>
            </a>
            <a href="exports"[ngClass]="{'active': page == 5}" class="side-bar-item">
                <app-svg class="side-svg" 
                    width="20px"
                    height="20px"
                    color="{page == 1 ? 'black' : 'white'}"
                    src="assets/svg/export.svg">
                </app-svg>
                <span>        
                    Exports
                </span>
            </a>
        </div>
    
    </div>

    <div class="w-25 h-100" (click)="closeSidebar()">

    </div>
</div>