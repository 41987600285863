<div class="page-container">

    <div class="login-form">
        <div class="form-header">
            <img src="assets/images/cignal_logo.png">
        </div>
          
            <div class="login-input-div">
                <label for="username">Username</label>
                <input type="text" [(ngModel)]="username">    
            </div>

            <div class="login-input-div">
                <label for="username">Password</label>
                <input type="password" [(ngModel)]="password">    
            </div>            
            
            <span *ngIf="login_message.trim()" class="login-message" >{{login_message}}</span>
              
            <button class="login-btn" [disabled]="isLoggingIn" (click)="loginUser()">
                <span>{{btn_text}}</span>
            </button>


    </div>

</div>