import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { NgChartsModule, NgChartsConfiguration } from 'ng2-charts';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SummaryCardComponent } from './summary-card/summary-card.component';
import { SalesOverviewComponent } from './sales-overview/sales-overview.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { SearchBarComponent } from './top-bar/search-bar/search-bar.component';
import { NotifAndAccountComponent } from './top-bar/notif-and-account/notif-and-account.component';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseOrderHistoryComponent } from './purchase-order-history/purchase-order-history.component';
import { ExportPageComponent } from './export-page/export-page.component';
import { SellOutHistoryComponent } from './sell-out-history/sell-out-history.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';
import { SellInComponent } from './sell-in/sell-in.component';
import { SvgComponent } from './svg/svg.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { PaginationComponent } from './reusables/pagination/pagination.component';
import { TpUsernameFilterComponent } from './reusables/tp-username-filter/tp-username-filter.component';
import { DateRangePickerComponent } from './reusables/date-range-picker/date-range-picker.component';
import { FilterSearchBarComponent } from './reusables/filter-search-bar/filter-search-bar.component';
import { SellOutComponent } from './sell-out/sell-out.component';
import { TpSellOutComponent } from './sell-out/tp-sell-out/tp-sell-out.component';
import { DealerSellOutComponent } from './sell-out/dealer-sell-out/dealer-sell-out.component';
import { RetailerSellOutComponent } from './sell-out/retailer-sell-out/retailer-sell-out.component';
import { RetailerSellInComponent } from './sell-in/retailer-sell-in/retailer-sell-in.component';
import { TpSellInComponent } from './sell-in/tp-sell-in/tp-sell-in.component';
import { DealerSellInComponent } from './sell-in/dealer-sell-in/dealer-sell-in.component';
import { MobileTableSortComponent } from './reusables/mobile-table-sort/mobile-table-sort.component';
import { ClassificationFilterComponent } from './reusables/classification-filter/classification-filter.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    FooterComponent,
    SidebarComponent,
    DashboardComponent,
    SummaryCardComponent,
    SalesOverviewComponent,
    TopBarComponent,
    SearchBarComponent,
    NotifAndAccountComponent,
    TransactionListComponent,
    PurchaseOrderHistoryComponent,
    ExportPageComponent,
    SellOutHistoryComponent,
    SellInComponent,
    SvgComponent,
    SafeHtmlPipe,
    PaginationComponent,
    TpUsernameFilterComponent,
    DateRangePickerComponent,
    FilterSearchBarComponent,
    SellOutComponent,
    TpSellOutComponent,
    DealerSellOutComponent,
    RetailerSellOutComponent,
    RetailerSellInComponent,
    TpSellInComponent,
    DealerSellInComponent,
    MobileTableSortComponent,
    ClassificationFilterComponent,
  ],
  imports: [
    NgChartsModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatSortModule
  ],
  providers: [
    {provide: NgChartsConfiguration, useValue: { generateColors: false }}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
