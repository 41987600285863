import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-classification-filter',
  templateUrl: './classification-filter.component.html',
  styleUrls: ['./classification-filter.component.css']
})
export class ClassificationFilterComponent {

  constructor( public globalService : GlobalService){}
 
  @Input() classificationFilterItems:Array<Array<string>> = [];
  @Output() updateClassificationFilter = new EventEmitter<string>();
  classificationFilter : string = "";

  setClassificationFilter(value:string){
    this.classificationFilter = value;
    this.updateClassificationFilter.emit(value);
  }

  clearClassificationFilter(){
    this.classificationFilter = "";
  }
}
