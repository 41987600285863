import { Injectable } from '@angular/core';
import { AuthService } from './../../auth.service';

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import { firstValueFrom, lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { GlobalService } from './../../global.service';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesTransactionsService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    public globalService: GlobalService
  ) { }

// SALES TRANSACTION TABLE RELATED APIs
  // REGULAR REQUEST
  async getSalesRecord(limit:number = 20, offset:number = 0){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sales?limit=${limit}&offset=${offset}`;
      
        try {
          var response = await lastValueFrom<HttpResponse<any>>(
            this.http.get(APIEndPoint, {
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                },
                observe: 'response',
              }
            )
          );  
          return {
            "items": response.body.items,
            "message": "",
          }; 
          
        }catch (e:any) {
          console.log(e.error.message);
          return {
            "items": [],
            "message": e.error.message,
          }; 
        }
    }else{
      return false;
    }
  }
  async getSalesTotalRecord(){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sales/total`;
      
        try {
          var response = await lastValueFrom<HttpResponse<any>>(
            this.http.get(APIEndPoint, {
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                },
                observe: 'response',
              }
            )
          );  
          return {
            "total": response.body.total,
            "message": "",
          }; 
          
        }catch (e:any) {
          console.log(e.error.message);
          return {
            "total": 0,
            "message": e.error.message,
          }; 
        }
    }else{
      return false;
    }
  }

  // SEARCH REQUEST 
  async searchSales(limit:number = 20, offset:number = 0, searchValue:string, sort_by:string, sort_order:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sales/search/by/term`;
      var body:any = {};

      body.search_term = searchValue;
      body.limit = limit;
      body.offset = offset;

      if (sort_by && sort_order) {
        body.sort_by = sort_by,
        body.sort_order = sort_order
      }
      
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );  
        return {
          "items": response.body.items,
          "message": "",
        }; 
      }catch (e:any) {
        console.log(e.error.message);
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }

    

    }else{
      return false;
    }
  }
  async searchSalesTotal(searchValue:string){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sales/search/by/term/total`;
      var body:any = {};

      body.search_term = searchValue;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
          this.http.post(APIEndPoint, body, {
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
              observe: 'response',
            }
          )
        );  
        return {
          "total": response.body.total,
          "message": "",
        }; 
      }catch (e:any) {
        console.log(e.error.message);
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }

    

    }else{
      return false;
    }
  }

  // FILTER REQUEST
  async filterSales(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sales/search/by/criteria`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
            this.http.post(APIEndPoint, body, {
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                },
                observe: 'response',
              }
            )
        );    
  
        return {
          "items": response.body.items,
          "message": "",
        }; 
        
      }catch (e:any) {
        if (e.error.message.includes("A date range is required.")) {
          e.error.message = "Date range is required.";
        }
        return {
          "items": [],
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }
  async filterSalesTotal(filterJson:any){
    var isTokenValid = await this.authService.checkAccessTokenValidity();
    if (isTokenValid) {
      var APIEndPoint:string = `${environment.cignalApiUrl}/sales/search/by/criteria/total`;
      var body = filterJson;
      try {
        var response = await lastValueFrom<HttpResponse<any>>(
            this.http.post(APIEndPoint, body, {
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                },
                observe: 'response',
              }
            )
        );    
  
        return {
          "total": response.body.total,
          "message": "",
        }; 
        
      }catch (e:any) {
        if (e.error.message.includes("A date range is required.")) {
          e.error.message = "Date range is required.";
        }
        return {
          "total": 0,
          "message": e.error.message,
        }; 
      }
    }else{
      return false;
    }
  }
}
