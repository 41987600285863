import { Component, NgModule, ViewChild } from '@angular/core';
import { CignalService } from '../cignal.service';
import { GlobalService } from '../global.service';
import { Router } from '@angular/router';
import { FilterSearchBarComponent } from '../reusables/filter-search-bar/filter-search-bar.component';
import { TpUsernameFilterComponent } from '../reusables/tp-username-filter/tp-username-filter.component';
import { DateRangePickerComponent } from '../reusables/date-range-picker/date-range-picker.component';
import { ClassificationFilterComponent } from '../reusables/classification-filter/classification-filter.component';
import { Sort } from '@angular/material/sort';
import { MobileTableSortComponent } from '../reusables/mobile-table-sort/mobile-table-sort.component';
import { SelloutService } from '../services/sell-out/sell-out.service';
@Component({
  selector: 'app-sell-out',
  templateUrl: './sell-out.component.html',
  styleUrls: ['./sell-out.component.css']
})
export class SellOutComponent {

  @ViewChild(FilterSearchBarComponent) 
  private SearchBar!:FilterSearchBarComponent;
  @ViewChild(TpUsernameFilterComponent) 
  private tpUsernameFilter!:TpUsernameFilterComponent;
  @ViewChild(DateRangePickerComponent) 
  private dateRangePicker!:DateRangePickerComponent;
  @ViewChild(ClassificationFilterComponent) 
  private classificationFilter!:ClassificationFilterComponent;
  @ViewChild(MobileTableSortComponent) 
  private mobileTableSortComponent!:MobileTableSortComponent;

  constructor(
    private cignalService: CignalService,
    public globalService: GlobalService,
    private router: Router,
    private sellOutServices: SelloutService,
  ){}
  
  isMobile : boolean = false;
  sellOutData : Array<any> = [];
  currentTab = "tp";
  requestID : number = 0;
  requestTotalID : number = 0;

  isLoading : boolean = false;
  isTotalLoading : boolean = false;
  message : any = "";

  sort_order : string = '';
  sort_by : string = '';


  ngOnInit(){
    this.isMobile = this.globalService.isMobile();
    this.loadInitialResult();
  }

  async loadInitialResult(){
    this.isTotalLoading = true;
    await this.getSellOutData();
    await this.getSellOutTotalData();
  }

  // PAGINATION
  current_page = 1;
  total_page = 1;
  updateSellInRecordPage(page:number){
    this.current_page = page;
    this.getSellOutData();
  }

  // SEARCH FILTER
  searchValue:string = "";
  updateSearchValue(keyWord:string){
    this.searchValue = keyWord;
    this.clearFilters();
    this.current_page = 1;
    this.loadInitialResult()
  }

  // SELL-IN FILTER
  sellOutFilter : any = {};
  
  // TP NAME FILTER
  updateTpNameFilter(newTpName:string){
    this.clearSearch();
    this.sellOutFilter.tpNameFilter = newTpName.trim();
    if (!this.sellOutFilter.tpNameFilter) {
      delete this.sellOutFilter.tpNameFilter;  
    }
    this.current_page = 1;
    this.loadInitialResult();
  }
  
  // DATE RANGE FILTER
  updateDateRangeFilter(newDateRangeFilter:any){
    this.clearSearch();
    delete this.sellOutFilter.start_date;
    delete this.sellOutFilter.end_date;

    if (typeof newDateRangeFilter.start_date === 'object') {
      this.sellOutFilter.start_date = this.globalService.formatNgbDateStructToString(newDateRangeFilter.start_date);      
    }
    if (typeof newDateRangeFilter.end_date === 'object') {
      this.sellOutFilter.end_date = this.globalService.addOneDayToEndDate(newDateRangeFilter.end_date);      
    }
    this.current_page = 1;
    this.loadInitialResult();
  }
  
  // CLASSIFICATION FILTER
  updateClassificationFilter(newClassification:string){
    this.clearSearch();
    this.sellOutFilter.classification = newClassification.trim();
    if (!this.sellOutFilter.classification) {
      delete this.sellOutFilter.classification;  
    }
    this.current_page = 1;
    this.loadInitialResult();

  }
  updateClassificationFilterItems(){
    switch (this.currentTab) {
      case 'tp':
        return [
          ["TERRITORIAL PARTNER","TP"],
          ["DEALER","DEALER"],
          ["SUBDEALER","SUBDEALER"],
          ["RETAILER","RETAILER"],
        ];
      case 'dealer':
        return [
          ["DEALER","DEALER"],
          ["SUBDEALER","SUBDEALER"],
          ["RETAILER","RETAILER"],
        ];
      default:
        return [];
    }
  }

  //  CLEAR SEARCH AND FILTERS
  clearFilters(){
    this.tpUsernameFilter.clearTpNameFilter();
    this.dateRangePicker.clearDateRange();
    if (this.classificationFilter) {
      this.classificationFilter.clearClassificationFilter();
    }
    this.sellOutFilter = {};
  }
  clearSearch(){
    this.SearchBar.clearSearch();
    this.searchValue = "";
  }
  clearAllFilters(){
    this.clearSearch();
    this.clearFilters();
    this.current_page = 1;
    this.loadInitialResult();
  }

  // EXPORT
  async exportSellOutRecord(){
    this.message = "";
    // get sales record by search
    if (this.searchValue) {
      
      switch (this.currentTab) {
        case 'tp':
          var result = await this.cignalService.exportSellOutTpByTerms(this.searchValue);
          break;
      
        case 'dealer':
          var result = await this.cignalService.exportSellOutDealerByTerms(this.searchValue);
          break;
            
        case 'retailer':
          var result = await this.cignalService.exportSellOutRetailerByTerms(this.searchValue);
          break;
        default:
          result = {success: false, message:`there is no ${this.currentTab} tab.`};
          break;
      }
    }
    // get sales record by filter
    else if(Object.keys(this.sellOutFilter).length > 0){
      
      var jsonFilter = this.formatFilterJson(this.sellOutFilter, 0, 0, '', '');
      
      switch (this.currentTab) {
        case 'tp':
          var result = await this.cignalService.exportSellOutTpByCriteria(jsonFilter);
          break;
      
        case 'dealer':
          var result = await this.cignalService.exportSellOutDealerByCriteria(jsonFilter);
          break;
            
        case 'retailer':
          var result = await this.cignalService.exportSellOutRetailerByCriteria(jsonFilter);
          break;
        default:
          result = {success: false, message:`there is no ${this.currentTab} tab.`};
          break;
      }
      // get all sales record

    }
    else{
      switch (this.currentTab) {
        case 'tp':
          var result = await this.cignalService.exportSellOutTp();
          break;
      
        case 'dealer':
          var result = await this.cignalService.exportSellOutDealer();
          break;
            
        case 'retailer':
          var result = await this.cignalService.exportSellOutRetailer();
          break;
        default:
          result = {success: false, message:`there is no ${this.currentTab} tab.`};
          break;
      }
    }

    if (result) {
      if (result.success) {

        this.globalService.showSnackbar(result.message,"Ok");
        this.router.navigateByUrl("/exports");
      
      }else{
        console.log(result.message);
        this.message = result.message;
      }
    }
  }

  // SELECT TAB
  selectTab(tabName:string){
    if (this.currentTab != tabName) {
      this.currentTab = tabName;
      this.clearFilters();
      this.clearSearch();
      if (this.isMobile) {
        this.mobileTableSortComponent.clearSort();
      }
      this.updateSort({direction:'',active:''});
      this.current_page = 1;
      this.total_page = 1;
      this.loadInitialResult();
    }
  }

  // SORT SECTION
  updateSortMenuItem(){
    switch (this.currentTab) {
      case 'tp':
        return [
          ['Territorial Partner','tp_username'],
          ['e-Pins Denoms','product_name'],
          ['Amount','amount'],
          ['Date','create_date'],
          ['Name','name'],
          ['Classification','classification'],
          ['Subscriber','subscriber'],
        ];
      case 'dealer':
        return [
          ['Dealer','dealer_username'],
          ['Territorial Partner','tp_username'],
          ['e-Pins Denoms','product_name'],
          ['Amount','amount'],
          ['Date','create_date'],
          ['Name','name'],
          ['Classification','classification'],
          ['Subscriber','subscriber'],
        ];
      case 'retailer':
        return [
          ['Retailer','retailer_username'],
          ['Dealer','dealer_username'],
          ['Territorial Partner','tp_username'],
          ['e-Pins Denoms','product_name'],
          ['Amount','amount'],
          ['Date','create_date'],
          ['Subscriber','subscriber'],
        ];
        
        break;
      default:
        return [];
    }
  }
  updateSort(sort:Sort){
    if (sort.direction) {
      this.sort_order = sort.direction;
      this.sort_by = sort.active;
    }else{
      this.sort_order = "";
      this.sort_by = "";
    }
    this.getSellOutData();
  }

  // GET SELL OUT DATA
  async getSellOutData(){
    this.message = "";
    this.isLoading = true;
    var itemsPerPage = this.isMobile ? 5 : 15 ;
    var dataOffset = (this.current_page*itemsPerPage)-itemsPerPage;
    this.sellOutData = [];

    
    this.requestID += 1;
    var requestID = this.requestID;

    if (this.searchValue) {
      switch (this.currentTab) {
        case 'tp':

          var result = await this.sellOutServices.searchSellOutTp( itemsPerPage, dataOffset, this.searchValue, this.sort_by, this.sort_order )
          this.mountData(result, itemsPerPage, requestID);
          
          break;
        case 'dealer':
          
          var result = await this.sellOutServices.searchSellOutDealer( itemsPerPage, dataOffset, this.searchValue, this.sort_by, this.sort_order )
          this.mountData(result, itemsPerPage, requestID);

          break;
        case 'retailer':

        var result = await this.sellOutServices.searchSellOutRetailer( itemsPerPage, dataOffset, this.searchValue, this.sort_by, this.sort_order )
        this.mountData(result, itemsPerPage, requestID);

          break;
        default:
          break;
        }
    }
    else if (Object.keys(this.sellOutFilter).length > 0) {
      var jsonFilter = this.formatFilterJson(this.sellOutFilter, itemsPerPage, dataOffset, this.sort_order, this.sort_by);
      switch (this.currentTab) {
        case 'tp':

          var result = await this.sellOutServices.filterSellOutTp(jsonFilter);
          this.mountData(result, itemsPerPage, requestID);
          
          break;
        case 'dealer':
          console.log('asd');
          var result = await this.sellOutServices.filterSellOutDealer(jsonFilter);
          this.mountData(result, itemsPerPage, requestID);

          break;
        case 'retailer':

          var result = await this.sellOutServices.filterSellOutRetailer(jsonFilter);
          this.mountData(result, itemsPerPage, requestID);

          break;
        default:
          break;
      }
    } else {
      switch (this.currentTab) {
        case 'tp':
          var result = await this.sellOutServices.getSellOutTpRecord(itemsPerPage, dataOffset, this.sort_by, this.sort_order);
          this.mountData(result, itemsPerPage, requestID);
          
          break;
        case 'dealer':

          var result = await this.sellOutServices.getSellOutDealerRecord(itemsPerPage, dataOffset, this.sort_by, this.sort_order);
          this.mountData(result, itemsPerPage, requestID);

          break;
        case 'retailer':
          
          var result = await this.sellOutServices.getSellOutRetailerRecord(itemsPerPage, dataOffset, this.sort_by, this.sort_order);
          this.mountData(result, itemsPerPage, requestID);

          break;
        default:
          break;
      }
    }
  }
  mountData(result: any, itemsPerPage: number, requestID: number){
    if (this.requestID == requestID) {
      if(result){
        if (result.message) {
          this.message = result.message;
        }else{
          this.sellOutData = result.items;
          // if((result.total % itemsPerPage) == 0){
          //   this.total_page = Math.floor(result.total / itemsPerPage );
          // }else{
          //   this.total_page = Math.floor(result.total / itemsPerPage ) + 1;
          // }
        }
      }
      
    if (this.sellOutData.length == 0 && !this.message) {
      this.message = 'No item available.'
    }
    this.isLoading = false;
    }
  }

  // GET TOTAL SELL OUT DATA
  async getSellOutTotalData(){
    var itemsPerPage = this.isMobile ? 5 : 15 ;
    var dataOffset = (this.current_page*itemsPerPage)-itemsPerPage;

    
    this.requestTotalID += 1;
    var requestTotalID = this.requestTotalID;

    if (this.searchValue) {
      switch (this.currentTab) {
        case 'tp':

          var result = await this.sellOutServices.searchSellOutTpTotal( this.searchValue)
          this.mountDataTotal(result, itemsPerPage, requestTotalID);
          
          break;
        case 'dealer':
          
          var result = await this.sellOutServices.searchSellOutDealerTotal( this.searchValue )
          this.mountDataTotal(result, itemsPerPage, requestTotalID);

          break;
        case 'retailer':

        var result = await this.sellOutServices.searchSellOutRetailerTotal( this.searchValue )
        this.mountDataTotal(result, itemsPerPage, requestTotalID);
        
          break;
        default:
          break;
        }
    }
    else if (Object.keys(this.sellOutFilter).length > 0) {
      var jsonFilter = this.formatFilterJson(this.sellOutFilter, itemsPerPage, dataOffset, this.sort_order, this.sort_by);
      switch (this.currentTab) {
        case 'tp':

          var result = await this.sellOutServices.filterSellOutTpTotal(jsonFilter);
          this.mountDataTotal(result, itemsPerPage, requestTotalID);
          
          break;
        case 'dealer':

          var result = await this.sellOutServices.filterSellOutDealerTotal(jsonFilter);
          this.mountDataTotal(result, itemsPerPage, requestTotalID);

          break;
        case 'retailer':

          var result = await this.sellOutServices.filterSellOutRetailerTotal(jsonFilter);
          this.mountDataTotal(result, itemsPerPage, requestTotalID);

          break;
        default:
          break;
      }
    } 
    else {
      switch (this.currentTab) {
        case 'tp':

          var result = await this.sellOutServices.getSellOutTpTotalRecord();
          this.mountDataTotal(result, itemsPerPage, requestTotalID);
          
          break;
        case 'dealer':

          var result = await this.sellOutServices.getSellOutDealerTotalRecord();
          this.mountDataTotal(result, itemsPerPage, requestTotalID);

          break;
        case 'retailer':
          
          var result = await this.sellOutServices.getSellOutRetailerTotalRecord();
          this.mountDataTotal(result, itemsPerPage, requestTotalID);

          break;
        default:
          break;
      }
    }
  }
  mountDataTotal(result: any, itemsPerPage: number, requestTotalID: number){
    if (this.requestTotalID == requestTotalID) {
      if(result){
        if (result.message) {
          this.message = result.message;
        }else{
          // this.sellOutData = result.items;
          if((result.total % itemsPerPage) == 0){
            this.total_page = Math.floor(result.total / itemsPerPage );
          }else{
            this.total_page = Math.floor(result.total / itemsPerPage ) + 1;
          }
        }
      }
    
      this.isTotalLoading = false;
    }
  }

  // JSON FILTER
  formatFilterJson(filterJson : any, limit: number, offset: number, sort_order: string, sort_by : string){

    var filter : any = {};
    if (filterJson.tpNameFilter) { 
      filter.tp_username = filterJson.tpNameFilter;
    }
    if (filterJson.classification) { 
      filter.classification = filterJson.classification;
    }
    if (filterJson.end_date) { 
      filter.to_date = filterJson.end_date;
    }
    if (filterJson.start_date) {
      filter.from_date = filterJson.start_date;
    }
    if (sort_order && sort_by) {
      filter.sort_order = sort_order;
      filter.sort_by = sort_by;
    }

    filter.limit = limit;
    filter.offset = offset;

    return filter;
  }

  // SHOW/HIDE MOBILE VIEW FILTER
  showFilter(val:boolean){
    let sidebar = document.getElementById("filter_div") as HTMLElement;
    if (sidebar) {
      var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      console.log(width);
      if (width < 600) {
        if (val) {
          sidebar.style.display = "flex";
        }else{
          sidebar.style.display = "none";
        } 
      }
    }
  }

}

