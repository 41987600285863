import { Component } from '@angular/core';
import { GlobalService } from '../global.service';
import { CignalService } from '../cignal.service';
import { Router } from '@angular/router';
import {Sort} from '@angular/material/sort';
import { SellOutHistoryService } from '../services/sell-out-history/sell-out-history.service';

@Component({
  selector: 'app-sell-out-history',
  templateUrl: './sell-out-history.component.html',
  styleUrls: ['./sell-out-history.component.css']
})
export class SellOutHistoryComponent {
  orderHistoryRecords:Array<any> = [];
  sellOutRecords:Array<any> = [];
  total_page:number = 0;
  current_page:number = 1;
  isLoading:boolean = false;
  isTotalLoading:boolean = false;
  requestId:number = 0;
  requestTotalId:number = 0;
  isMobile : boolean = false;
  
  // search variables
  temp_searchValue:string = "";
  searchValue:string = "";

  
  // filter variables
  tp_usernames:Array<any> = [];
  tp_usernames_bkup:Array<any> = [];
  tpNameFilter: string = "";

  filterJson:any = {};
  message:string = "";
  
  constructor(
    private cignalService: CignalService,
    public globalService: GlobalService,
    private router: Router,
    private sellOutHistoryService : SellOutHistoryService
  ){}
  
  ngOnInit(){
    this.isMobile = this.globalService.isMobile();
    this.getAllTpNames();
    this.initializeData();
  }

  async initializeData(){
    this.isTotalLoading = true;
    await this.getSellOutHistoryRecord();
    await this.getSellOutHistoryTotalRecord();
    
  }

  // GET DISPLAY DATA
  async getSellOutHistoryRecord(){
    
    delete this.filterJson.limit;
    delete this.filterJson.offset;
    this.message = "";
    this.isLoading = true;
    this.requestId += 1;
    var requestId = this.requestId;
    var itemsPerPage = this.isMobile ? 5 : 15 ;
    var offset = (this.current_page * itemsPerPage) - itemsPerPage; 

    // get sales record by search
    if (this.searchValue) {
      var sort_by = this.filterJson.sort_by ?? "";
      var sort_order = this.filterJson.sort_order ?? "";

     var result = await this.sellOutHistoryService.searchSellOut(itemsPerPage, offset, this.searchValue, sort_by, sort_order);
    }
    // get sales record by filter
    else if(Object.keys(this.filterJson).length > 0)
    {
     this.filterJson.limit = itemsPerPage;
     this.filterJson.offset = (this.current_page * itemsPerPage) - itemsPerPage;
     var result = await this.sellOutHistoryService.filterSellOutHistory(this.filterJson);
    }
    // get all sales record
    else{
      var result = await this.sellOutHistoryService.getSellOutHistoryRecord(itemsPerPage, offset);
    }

    this.mountData(result, requestId);

  }
  mountData(result: any, requestId: number){
    
    if (requestId == this.requestId) {
      
      if (result) {
       if (result.message) {
         this.message = result.message;
       }else{
         this.sellOutRecords = result.items;
       }
      }
      if (this.sellOutRecords.length == 0 && !this.message) {
        this.message = 'No item available.'
      }
      this.isLoading = false;
      
    }
  }

  // GET TOTAL ITEM
  async getSellOutHistoryTotalRecord(){
    
    delete this.filterJson.limit;
    delete this.filterJson.offset;
    this.requestTotalId += 1;
    var requestTotalId = this.requestTotalId;
    var itemsPerPage = this.isMobile ? 5 : 15 ;

    // get sales record by search
    if (this.searchValue) {

     var result = await this.sellOutHistoryService.searchSellOutTotal(this.searchValue);
    }
    // get sales record by filter
    else if(Object.keys(this.filterJson).length > 0)
    {
     var result = await this.sellOutHistoryService.filterSellOutHistoryTotal(this.filterJson);
    }
    // get all sales record
    else{
      var result = await this.sellOutHistoryService.getSellOutHistoryTotalRecord();
    }
    this.mountTotalData(result,itemsPerPage,requestTotalId);
  }
  mountTotalData(result: any, itemsPerPage: number, requestTotalId: number){
    console.log(result);
    
    if (requestTotalId == this.requestTotalId) {
      
      if (result) {
       if (result.message) {
         this.message = result.message;
       }else{
         if((result.total % itemsPerPage) == 0){
           this.total_page = Math.floor(result.total / itemsPerPage );  
         }else{
           this.total_page = Math.floor(result.total / itemsPerPage ) + 1;
         }
       }
      }
      this.isTotalLoading = false;
      
    }
  }

  // FILTERS
  async getAllTpNames(){
    var result = await this.cignalService.getAllTpNames();
    if (result) {
      if (result instanceof Array) {
        this.tp_usernames = result;
        this.tp_usernames_bkup = result;
      }
    }
  }
  setTpNameFilter(tpName:string){
    this.tpNameFilter = tpName;
    this.setOrderRecordFilter();
  }
  tpNameFilterChange(){
      this.tp_usernames = this.tp_usernames_bkup;
      if(this.tpNameFilter){
        this.tp_usernames = this.tp_usernames.filter(
          tp_username => tp_username.tp_username.indexOf(this.tpNameFilter) >= 0);
      }
  }

  // EXPORT RECORD
  async exportSellOutRecord(){
    var offset = (this.current_page * 10) - 10; 
    this.message = "";

    // get sales record by search
    if (this.searchValue) {
     var result = await this.cignalService.exportSellOutByTerms(this.searchValue);
    }
    // get sales record by filter
    else if(Object.keys(this.filterJson).length > 0)
    {
     var result = await this.cignalService.exportSellOutByCriteria(this.filterJson);
    }
    // get all sales record
    else{
      var result = await this.cignalService.exportSellOutRecord();
    }

    if (result) {
      if (result.success) {

        this.globalService.showSnackbar(result.message,"Ok");
        this.router.navigateByUrl("/exports");
      
      }else{
        this.message = result.message;
      }
    }
  }
  
  // FILTER FUNCTIONS   
  setOrderRecordFilter(){
    var filterJson:any = {};
    
    if (this.tpNameFilter) { filterJson.tp_username = this.tpNameFilter; }

    this.current_page = 1;
    this.filterJson = filterJson;
    this.searchValue = "";
    this.temp_searchValue = "";
    
    this.initializeData();
  }
  showFilter(val:boolean){
    let sidebar = document.getElementById("filter_div") as HTMLElement;
    if (sidebar) {
      var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      if (width < 600) {
        if (val) {
          sidebar.style.display = "flex";
        }else{
          sidebar.style.display = "none";
        } 
      }
    }
  }

  // SORT FUNCTION
  sortData(sort:Sort){
    delete  this.filterJson.sort_by;
    delete  this.filterJson.sort_order;
    if (sort.direction) {
      this.filterJson.sort_by = sort.active;
      this.filterJson.sort_order = sort.direction;
    }
    this.getSellOutHistoryRecord();
  }

  // PAGINATION
  updateSellOutHistoryRecordPage(pageNo:number){
    this.current_page = pageNo;
    this.getSellOutHistoryRecord();
  }

  // SEARCH
  searchSellOut(){
    if (this.searchValue.trim() != this.temp_searchValue.trim()) {
        this.searchValue = this.temp_searchValue.trim();
        this.current_page = 1;
      //  reset filter value
        this.tpNameFilter = "";
        this.filterJson = {};
    }
    this.showFilter(false);
    this.initializeData();
  }
  clearsearchSellOut(){
    this.searchValue = "";
    this.temp_searchValue = "";
    this.current_page = 1;
    //  reset filter value
    this.tpNameFilter = "";
    this.filterJson = {};

    this.showFilter(false);
    this.initializeData();
  }
}
