import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent {
  username:string = "";
  password:string = "";
  btn_text:string = "Log-in";
  isLoggingIn:boolean = false;
  login_message:string = "";

  constructor(
    private router:Router,
    private authService: AuthService
    ){}

  async loginUser(){
    this.isLoggingIn = true;
    this.btn_text = "Loading...";
    var login_response = await this.authService.sendLoginRquest(this.username, this.password);
    if(login_response.status){
      this.router.navigateByUrl('/dashboard');
    }else{
      this.login_message = login_response.message;
    }
    this.btn_text = "Log-in";
    this.isLoggingIn = false;
  }

}
