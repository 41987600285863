import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-retailer-sell-in',
  templateUrl: './retailer-sell-in.component.html',
  styleUrls: ['./retailer-sell-in.component.css']
})
export class RetailerSellInComponent {
  
  @Input() isLoading : boolean = false;
  @Input() message : String = "";
  @Input() sellInData : Array<any> = [];
  isMobile : boolean = false;
  @Output() updateTpNameFilter = new EventEmitter<Sort>();

  constructor(
    public globalservice : GlobalService
  ){}

  ngOnInit(){
    this.isMobile = this.globalservice.isMobile();
  }
  
  sortData(sort:Sort){
    this.updateTpNameFilter.emit(sort);
  }

}
