import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { firstValueFrom, lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from './../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private http: HttpClient) { }

  
  async sendLoginRquest(username:string, password:string){
    
    var loginAPIEndPoint:string = `${environment.authApiUrl}/login`;  
    var body = {
      "username": username,
      "password": password 
    };

    try {
      var response = await lastValueFrom<HttpResponse<any>>(
        this.http.post(loginAPIEndPoint, body, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response',}
        )
      );       
      if (response.status == 200) {

        if (!response.body.message) {
          if (response.body.refresh_token && response.body.access_token && response.body.expires_in && response.body.user) {
           
            localStorage.setItem('refresh_token', response.body.refresh_token);
            localStorage.setItem('access_token', response.body.access_token);
            localStorage.setItem('access_token_expires_in', response.body.expires_in);
            localStorage.setItem('user', JSON.stringify(response.body.user));
          
            return {
              status: true,
              message: "Log in successful."
            };
          }
          
          return {
            status: false,
            message: "Something went wrong, please Try again."
          };
          
        }else{
          return {
            status: false,
            message: `${response.body.message}.`
          }; 
        }
        
      }else{
        return {
          status: false,
          message: "Something went wrong."
        };
      }
    
    } catch (e:any) {
      return {
        status: false,
        message: `${e.error.message}.`
      }; 
    } 
    
  }

  async sendLogoutRquest(){

    var logoutAPIEndPoint:string = `${environment.authApiUrl}/logout`;  
    var refresh_token = localStorage.getItem("refresh_token");
    
    console.log(refresh_token);

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        "refresh_token": refresh_token,
      },
    };

    try {  
      var response = await lastValueFrom<any>(this.http.delete(logoutAPIEndPoint, options ));
      if(response){
        return false; 
      }else{
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("access_token");
        localStorage.removeItem("access_token_expires_in");
        localStorage.removeItem("user");
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }

  }

  async checkAccessTokenValidity(){
    
    var access_token_expires_in = parseInt(localStorage.getItem("access_token_expires_in") ?? '0');
    var current_time = Math.round(((new Date).getTime())/1000);
    
    if ((access_token_expires_in - current_time) > 0) {
      return true;
    }else{
      return await this.getNewAccessToken();
    }
  }

  async getNewAccessToken(){
    var APIEndPoint:string = `${environment.authApiUrl}/token`;  
    var body = {
      "refresh_token": localStorage.getItem("refresh_token")
    };

    try {
      var response = await lastValueFrom<HttpResponse<any>>(
        this.http.post(APIEndPoint, body, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response',}
        )
      );    
      if (response.status == 200) {
        if (response.body.message) {
          return false;
        }else{ 
          localStorage.setItem('access_token', response.body.access_token);
          localStorage.setItem('access_token_expires_in', response.body.expires_in);
          return true;
        }
      }else{
        return false;
      }
    
    } catch (error: any) {
      if (error.error.message == "Refresh token does not exist") {
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("access_token");
        localStorage.removeItem("access_token_expires_in");
        localStorage.removeItem("user");
        this.router.navigateByUrl('/login');
      }
      return false; 
    } 
    

  }

}
