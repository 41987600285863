import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  hideSideAndTopBar: boolean = false;

  constructor(
    private router: Router,
    public globalService: GlobalService
    ) {
    this.hideSideAndTopBar = (location.pathname == "/login" || location.pathname == "/") 
  }
  
  ngOnInit(): void{
    
    if (localStorage.getItem("refresh_token") && localStorage.getItem("refresh_token") != 'undefined') {
      if (location.pathname == "/login" || location.pathname == "/") {
        this.router.navigateByUrl('/dashboard');
      }
    }else{
      this.router.navigateByUrl('/login');
    }

    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    console.log("refresh_token: ");
    console.log(`" ${localStorage.getItem("refresh_token")} "`);

    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    console.log("access_token: ");
    console.log(`" ${localStorage.getItem("access_token")} "`);

  }
}
